import { Button, Form, Label }      from "reactstrap"
import { Controller, useForm }      from "react-hook-form"
import { useContext, useEffect, useState }    from "react"

import Select                       from "react-select"
import ModalFilterConfig            from "../../../../@core/components/modal-filter-config"
import { RoleContext } from "../../../../context/roleContext"
import { ProfileContext } from "../../../../context/profileContext"

const RecyleBinFilter = (props) => {

    const {
        show,
        setShow,
        getData,
        params,
        setParams
    } = props

    const {
		reset,
        watch,
        getValues,
		control,
		setValue,
		setError,
		clearErrors,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode        : "onChange",
    })

    const [reportTypeOption, setReportTypeOption] = useState([])

    const {
        reportType, setReportType
    } = useContext(RoleContext)

    const onSubmit = (values) => {
        const filterParam = {
            report_type_id    : values?.report_type?.value,
        }

        const newParam = {
            ...params,
            ...filterParam,
            page        : 1,
        }

        setParams(newParam)

        getData(newParam)

        setShow(false)
    }

    const onReset = (values) => {

        const newParam = {
            page        : 1,
        }

        setParams(newParam)

        getData(newParam)

        setShow(false)

        reset()
    }

    useEffect(() => {
        if (reportType && reportType?.length > 0) {
            const list = []

            reportType.map((dt) => {
                const options = []

                dt.report?.map((dtReport) => {
                    options.push({
                        label   : `${dtReport?.name} - ${dtReport?.description}`,
                        value   : dtReport?.report_type
                    })
                })
                list.push({
                    label   : `${dt?.name}`,
                    options : options
                })
            })

            setReportTypeOption(list)
        }
    }, [reportType])

    return (
        <>
            <ModalFilterConfig
                show        = {show}
                setShow     = {setShow}
                size        = "sm"
                title       = "Filter Satuan Kerja"
            >
                <Form onSubmit={handleSubmit(onSubmit)}>

                    <div
                        className   = "mb-1"
                    >
                        <Label>Laporan</Label>
                        <Controller
                            name        = "report_type"
                            control     = {control}
                            render      = {({ field }) => (
                                <Select
                                    {...field} 
                                    className           = {`react-select ${errors.report_type?.value && "is-invalid"}`}
                                    classNamePrefix     = "select"
                                    placeholder         = "Laporan"
                                    options             = {reportTypeOption}
                                    isClearable         = {true}
                                />
                            )}
                        />
                        {console.log("reportType_1", reportType)}
                        {errors.report_type && <small className="text-danger">{errors.report_type?.value?.message}</small>}
                    </div>

                    <div
                        className       = "d-flex justify-content-between col-12 border-top pt-1"
                    >
                        <Button
                            color       = "primary"
                            outline
                            onClick     = {() => {
                                onReset()
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            color       = "primary"
                            type        = "submit"
                        >
                            Filter
                        </Button>
                    </div>
                </Form>
		    </ModalFilterConfig>
        </>
    )
}

export default RecyleBinFilter