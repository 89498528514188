import { useState, useContext, useEffect }     from "react"
import Select from "react-select"
import { 
    Button, 
    Input, 
    Label, 
    ListGroup, 
    ListGroupItem, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader 
}                       from "reactstrap"

const ModalOrderFilter = ({
    show,
    setShow,
    size,
    title,
    data,
    selectOrder, 
    setSelectOrder,
    selectOrderBy, 
    setSelectOrderBy,
    getData
}) => {

    // const [selectOrder, setSelectOrder] = useState(null)

    const orderByoption = [
        // { value: '"title"', label: 'Title' },
        // { value: 'category', label: 'Kategori' },
        { value: 'created_at', label: 'Tanggal' },
    ]

    const roleOption = [
        { value: 'asc', label: 'Terlama' },
        { value: 'desc', label: 'Terbaru' },
    ]

        // if (selectOrderBy === '"title"') {
        //     roleOption = [
        //         { value: 'asc', label: 'A-Z' },
        //         { value: 'desc', label: 'Z-A' },
        //     ]
        
        // } else if (selectOrderBy === 'created_at') {
        //     roleOption = [
        //         { value: 'asc', label: 'Terlama' },
        //         { value: 'desc', label: 'Terbaru' },
        //     ]
        // } else if (selectOrderBy === '"status"') {
        //     roleOption = [
        //         { value: 'asc', label: 'Publikasi Nasional' },
        //         { value: 'desc', label: 'Publikasi Lokal' },
        //     ]
        // }

    const onSubmit = (data) => {
        const params = {
            sort : selectOrder,
            sort_by : selectOrderBy
        }
        getData(params)
        setShow(false)
    }
   
    return (
        <>
            <Modal
                isOpen  = {show} 
                toggle  = {() => { setShow(!show) }} 
                size    = {size || "sm"}
            >
                <ModalHeader toggle={() => setShow(!show)} > 
                    {title}
                </ModalHeader>
                <ModalBody>

                {/* <Select
                    key={'select-role'}
                    options={orderByoption}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Pilih Order By"
                    onChange={(e) => setSelectOrderBy(e ? e.value : 'title')}
                /> */}
                <Select
                    options={roleOption}
                    className="basic-single mt-2"
                    classNamePrefix="select"
                    placeholder="Pilih Urutkan"
                    onChange={(e) => setSelectOrder(e ? e.value : 'asc')}
                />
                </ModalBody>
                <ModalFooter className="d-flex justify-content-between ">
                    <Button 
                        outline 
                        onClick={() => {
                            setShow(false)
                        }}
                    > 
                        Batal
                    </Button>
                    <Button color="primary" onClick={onSubmit}> Submit</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}

export default ModalOrderFilter