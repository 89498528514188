import { useEffect, useState }              from "react"
import { Nav, NavItem, NavLink }            from "reactstrap"

import toast                                from "react-hot-toast"
import ModalDelete                          from "../../other/ModalDelete"
import EvaluationIndicatorTable             from "./component/EvaluationIndicatorTable"
import EvaluationIndicatorCreate            from "./component/EvaluationIndicatorCreate"
import EvaluationIndicatorCreatePerformance from "./component/EvaluationIndicatorCreatePerformance"
import EvaluationIndicatorCreateCategory    from "./component/EvaluationIndicatorCreateCategory"

import AssessmentIndicatorAPI               from "../../../service/pages/assesment-indicator"

const EvaluationIndicatorConfiguration = () => {

    const [data, setData]                                 = useState([])
    const [params, setParams]                             = useState({})
    const [selected, setSelected]                         = useState([])
    const [isLoading, setIsLoading]                       = useState(null)
    const [clearSelected, setClearSelected]               = useState(false)
    const [perfomanceType, setPerfomanceType]             = useState('badge')
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)
    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [pagination, setPagination]   = useState(false)

    const getDataPoint = (params) => {
        setIsLoading(true)
        AssessmentIndicatorAPI.getDataPoint(params)
            .then(res => {
                setData(res.results)
                setIsLoading(false)
                const _pagination = {
                    count       : res.count,
                    page        : res.page,
                    page_size   : res.page_size,
                    next        : res.next,
                    previous    : res.previous
                }
                setPagination(_pagination)
            })
            .catch(() => {
                toast.error('Error')
            })
    }

    const getDataBadge = (params) => {
        setIsLoading(true)
    
        AssessmentIndicatorAPI.getDataBadge(params)
            .then(res => {
                setData(res.results)
                setIsLoading(false)
                const _pagination = {
                    count       : res.count,
                    page        : res.page,
                    page_size   : res.page_size,
                    next        : res.next,
                    previous    : res.previous
                }
                setPagination(_pagination)
            })
            .catch(() => {
                toast.error('Error')
            })
    }

    const handleDelete = () => {
       
        selected.map(data => {
            AssessmentIndicatorAPI.deleteData(perfomanceType, data?.id)
                .then(() => {
                    toast.success('Berhasil Hapus Data')
                    if (perfomanceType === 'badge') {
                        getDataBadge()
                    } else {
                        getDataPoint()
                    }
                })
                .catch(() => {
                    toast.error('Gagal Hapus Data')
                })
        })
    }

    useEffect(() => {
        if (clearSelected) {
            setClearSelected(!clearSelected)
        }
    }, [clearSelected])

    useEffect(() => {
        if (!isUpdateModalVisible) {
            setSelected([])
            setClearSelected(true)
        }
    }, [isUpdateModalVisible])

    useEffect(() => {
        if (!isDeleteModalVisible) {
            setSelected([])
            setClearSelected(true)
        }
    }, [isDeleteModalVisible])

    useEffect(() => {
        if (perfomanceType === 'badge') {
            getDataBadge(params)
        } else {
            getDataPoint(params)
        }
    }, [perfomanceType, params])

    return (
        <>
        <Nav 
            className="border-bottom"
            tabs fill
        >
            <NavItem>
                <NavLink 
                    onClick={() => {
                        setPerfomanceType('badge')
                    }}
                    active = {perfomanceType === 'badge' }
                >
                    Badge
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    onClick={() => {
                        setPerfomanceType('point')
                    }}
                    active = {perfomanceType === 'point' }
                >
                    Point
                </NavLink>
            </NavItem>
        </Nav>
            <EvaluationIndicatorTable
                data                    = {data}
                params                  = {params}
                setData                 = {setData}
                getData                 = {() => {}}
                loading                 = {isLoading}
                selected                = {selected}
                setParams               = {setParams}
                pagination              = {pagination}
                setSelected             = {setSelected}
                clearSelected           = {clearSelected}
                setClearSelected        = {setClearSelected}
                setIsCreateModalVisible = {setIsCreateModalVisible}
                setIsUpdateModalVisible = {setIsUpdateModalVisible}
                setIsDeleteModalVisible = {setIsDeleteModalVisible}
            />

            <EvaluationIndicatorCreate
                show        = {isCreateModalVisible}
                setShow     = {setIsCreateModalVisible}
                getDataPoint = {getDataPoint}
                getDataBadge ={getDataBadge}
                title       = "Tambah Indikator Penilaian"
                size        = "md"
                perfomanceType = {perfomanceType}
            />
            {
                perfomanceType === 'badge' ? (
                    <EvaluationIndicatorCreatePerformance
                        show        = {isUpdateModalVisible}
                        setShow     = {setIsUpdateModalVisible}
                        title       = "Ubah Pengaturan Penilaian"
                        size        = "lg"
                        value       = {selected[0]}
                        data        = {selected?.length === 1 ? selected[0] : null}
                        getData     = {getDataBadge}

                    />
                ) : (
                    <EvaluationIndicatorCreateCategory
                        show        = {isUpdateModalVisible}
                        setShow     = {setIsUpdateModalVisible}
                        title       = "Ubah Jenis Penilaian"
                        value       = {selected?.length === 1 ? selected[0] : null}
                        size        = "lg"
                        getData     = {getDataPoint}
                    />
                )
            }
          
            <ModalDelete
                show        = {isDeleteModalVisible}
                setShow     = {setIsDeleteModalVisible}
                size        = "sm"
                title       = "Hapus Indikator Penilaian"
                handleDelete= {handleDelete}
                data        = {selected}
            />

        </>
    )
}

export default EvaluationIndicatorConfiguration