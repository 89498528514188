import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap"

const ModalFilterConfig = ({
    show,
    setShow,
    size,
    title,
    children
}) => {
    return (
        <>
            <Modal
                isOpen          = {show}
                toggle          = {() => { setShow(!show) }}
                size            = {size || "sm"}
            >
                <ModalHeader
                    toggle      = {() => setShow(!show)}
                >
                    {title}
                </ModalHeader>

                <ModalBody>
                    <div>
                        {children}
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default ModalFilterConfig