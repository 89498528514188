import { useEffect, useState }      from "react"
import UserActivityTable            from "./component/UserActivityTable"
import ModalDelete                  from "../../other/ModalDelete"
import userActivityAPI              from "../../../service/pages/user-activity"
import UAParser                     from 'ua-parser-js'
import UserActivityFilter from "./component/UserActivityFilter"

const UserActivityConfiguration = () => {

    const [params, setParams]                             = useState(null)
    const [isLoading, setIsLoading]                       = useState(null)

    const [selected, setSelected]                           = useState([])
    const [clearSelected, setClearSelected]                 = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible]   = useState(false)
    const [isFilterModalVisible, setIsFilterModalVisible]   = useState(false)
    const [pagination, setPagination]                       = useState({})

    const [data, setData]                       = useState([])

    const getData = (params) => {
        setIsLoading(true)
        
        userActivityAPI.getUserActivity(params)
            .then((res) => {
                setIsLoading(false)
                setData(res?.results)

                setPagination({
                    count       : res.count,
                    page        : res.page,
                    page_size   : res.page_size,
                    next        : res.next,
                    previous    : res.previous,
                    total_data  : res.count
                })

                res?.results?.map((dt) => {
                    const parser        = new UAParser(`${dt?.user_agent}`) // you need to pass the user-agent for nodejs
                    const parserResults = parser.getResult()

                    const deviceName    = `${parserResults.os?.name ? parserResults.os?.name : ""} - ${parserResults?.os?.version ? parserResults?.os?.version : ""}`
                    dt.deviceName       = deviceName
                })
            })
    }

    const handleDelete = () => {

    }

    useEffect(() => {
        if (clearSelected) {
            setClearSelected(!clearSelected)
        }
    }, [clearSelected])

    useEffect(() => {
        if (!isDeleteModalVisible) {
            setSelected([])
            setClearSelected(true)
        }
    }, [isDeleteModalVisible])

    return (
        <>
            <UserActivityTable
                data                    = {data}
                params                  = {params}
                setData                 = {setData}
                getData                 = {getData}
                loading                 = {isLoading}
                selected                = {selected}
                setParams               = {setParams}
                pagination              = {pagination}
                setSelected             = {setSelected}
                clearSelected           = {clearSelected}
                setClearSelected        = {setClearSelected}
                setIsDeleteModalVisible = {setIsDeleteModalVisible}
                setIsFilterModalVisible = {setIsFilterModalVisible}
            />

            <ModalDelete
                show        = {isDeleteModalVisible}
                setShow     = {setIsDeleteModalVisible}
                size        = "sm"
                title       = "Hapus Log Aktivitas"
                handleDelete= {handleDelete}
                data        = {selected}
            />

            <UserActivityFilter
                show            = {isFilterModalVisible}
                setShow         = {setIsFilterModalVisible}
                getData         = {getData}
                params          = {params}
                setParams       = {setParams}
            />
        </>
    )
}

export default UserActivityConfiguration