import React, { useEffect, useState }                        from 'react'
import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { useForm, Controller }      from 'react-hook-form'
import { yupResolver }              from '@hookform/resolvers/yup'
import * as yup                     from 'yup'

import toast                        from "react-hot-toast"
import FileManagementAPI            from '../../../../service/pages/file-management'
import SwapSequence from './swap-sequence'
import { getUserData } from '../../../../utility/Utils'
import LabelRequired from '../../../../@core/components/label-required'


const schema = yup.object().shape({
    name: yup.string()
    .max(50, 'Nama terlalu panjang')
    .required('Isian Tidak Boleh Kosong'),
    sequence:yup.number()
    .typeError('Isian harus berupa angka') 
    .required('Isian Tidak Boleh Kosong'),
  })

const CategoryForm = ({show, setShow, params, setSelected, data_, size, title, getData}) => {

    const { handleSubmit, setValue, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
      })

      const [showSwapSequence, setShowSwapSequence] = useState(false)

      const getDataCategory = (params) => {
        
    }

      const create = (data) => {
        FileManagementAPI.createCategory(data)
        .then(() => {
            toast.success('Berhasil Menambahkan Data')
            getData()
            setShow(false)
            setSelected([])
            setValue('name', '')
            setValue('sequence', '')

        })
        .catch((err) => {
            console.log(err)
        })
      }

      const update = (data) => {
        FileManagementAPI.updateCategory(data_?.id, data)
            .then(() => {
                toast.success('Berhasil Ubah Data')
                getData(params)
                setShow(false)
                setSelected([])
            })
            .catch((err) => {
                console.log(err)
                toast.error(err?.result?.message)

            })
      }

      const onSubmit = (data) => {


        FileManagementAPI.getDetailCategory({sequence : data?.sequence})
            .then((res) => {
                if (res?.results[0]?.sequence !==  data?.sequence && res?.results[0]?.created_by !== getUserData()?.user_id) {
                    console.log(res?.results[0]?.sequence !==  data?.sequence)
                    if (!data_) {
                        create(data)
                    } else {
                        update(data)
                    }
                } else {
                    toast.error('Urutan Sudah Dipakai')
                    // setShowSwapSequence([res?.results[0], data])

                }
            })

     
      }

      useEffect(() => {
        if (show && data_) {
            setValue('name', data_?.name)
            setValue('sequence', data_?.sequence)

        } else {
            setValue('name', '')
            setValue('sequence', '')

        }
      }, [show && data_])

  return (
    <>
        <SwapSequence 
            show={showSwapSequence}
            setShow={setShowSwapSequence}
            getData = {getData}
        />
        <Modal
            isOpen  = {show} 
            toggle  = {() => { setShow(!show) }} 
            size    = {size || "sm"}
            centered
        >
            <ModalHeader 
                toggle={() => {
                    setShow(!show)
                    setSelected([])
                }} 
            > 
                {title}
            </ModalHeader>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalBody>
                <div className='mb-1'>
                        <Label>Masukkan Nama <LabelRequired /></Label>
                        <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    id="name"
                                    classNamePrefix     = "select"
                                    placeholder         = "Masukkan Kategori"
                                    {...field}
                                />
                            )}
                        />
                        {
                            errors && errors?.name && 
                                <small style={{ color: 'red' }}>
                                    {errors?.name?.message}
                                </small>
                        }
                    </div>
                    <div className='mb-1'>
                        <Label>Urutan <LabelRequired /></Label>
                        <Controller
                            name="sequence"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    id="sequence"
                                    type                = 'number'
                                    classNamePrefix     = "select"
                                    placeholder         = "Masukkan Urutan"
                                    {...field}
                                />
                            )}
                        />
                        {
                            errors && errors?.sequence && 
                                <small style={{ color: 'red' }}>
                                    {errors?.sequence?.message}
                                </small>
                        }
                    </div>
                </ModalBody>

                <ModalFooter>
                    <div
                        className   = "d-flex justify-content-between col-12"
                    >
                        <Button
                            color   = "primary"
                            outline
                            onClick = {() => {
                                setShow(false)
                            }}
                        >
                            Batal
                        </Button>

                        <Button
                            color   = "primary"
                            type="submit"
                        >
                            Simpan
                        </Button>
                    </div>
                </ModalFooter>
            </form>
        </Modal>
    </>
  )
}

export default CategoryForm