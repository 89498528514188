import { Button, Form, Label }      from "reactstrap"
import { Controller, useForm }      from "react-hook-form"
import { useContext, useEffect }    from "react"

import { WorkunitContext }          from "../../../../context/workunitContext"
import { RoleContext }              from "../../../../context/roleContext"
import { GradeContext }             from "../../../../context/gradeContext"
import { PositionContext }          from "../../../../context/positionContext"

import Select                       from "react-select"
import ModalFilterConfig            from "../../../../@core/components/modal-filter-config"

const UserManagementFilter = (props) => {

    const {
        show,
        setShow,
        getData,
        params,
        setParams
    } = props

    const {
		reset,
        watch,
        getValues,
		control,
		setValue,
		setError,
		clearErrors,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode        : "onChange",
    })

    const {
        roleOption
    } = useContext(RoleContext)

    const {
        gradeOption
    } = useContext(GradeContext)

    const {
        workunitOption
    } = useContext(WorkunitContext)

    const {
        positionOption,
        getDataPositionByWorkunit
    } = useContext(PositionContext)

    const onSubmit = (values) => {
        const filterParam = {
            work_unit    : values?.workunit?.value,
            position     : values?.position?.value,
            grade        : values?.grade?.value,
            role         : values?.role?.value
        }

        const newParam = {
            ...params,
            ...filterParam,
            page        : 1,
        }

        setParams(newParam)

        getData(newParam)

        setShow(false)
    }

    const onReset = (values) => {

        const newParam = {
            page        : 1,
        }

        setParams(newParam)

        getData(newParam)

        setShow(false)

        reset()
    }

    useEffect(async () => {
        if (watch('workunit')?.value) {
            getDataPositionByWorkunit(watch('workunit')?.value)
        }

        setValue("position", null)
        setTimeout(() => {
            setValue("position", undefined)
        }, 500)
    }, [watch('workunit')])

    return (
        <>
            <ModalFilterConfig
                show        = {show}
                setShow     = {setShow}
                size        = "sm"
                title       = "Filter Manajamen Pengguna"
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                
                    <div
                        className   = "mb-1"
                    >
                        <Label>Satuan Kerja</Label>
                        <Controller
                            name        = "workunit"
                            control     = {control}
                            render      = {({ field }) => (
                                <Select
                                    {...field} 
                                    className           = {`react-select ${errors.workunit?.value && "is-invalid"}`}
                                    classNamePrefix     = "select"
                                    placeholder         = "Satuan Kerja"
                                    options             = {workunitOption}
                                    isClearable         = {true}
                                />
                            )}
                        />
                        {errors.workunit && <small className="text-danger">{errors.workunit?.value?.message}</small>}
                    </div>

                    <div
                        className   = "mb-1"
                    >
                        <Label>Jabatan</Label>
                        <Controller
                            name        = "position"
                            control     = {control}
                            render      = {({ field }) => (
                                <Select
                                    {...field} 
                                    className           = {`react-select ${errors.position?.value && "is-invalid"}`}
                                    classNamePrefix     = "select"
                                    placeholder         = {`
                                        ${!watch("workunit") ?
                                            "Jabatan"
                                        : (watch("workunit") && positionOption?.length) === 0 ? 
                                            "Memuat Jabatan..." 
                                        : "Pilih Jabatan" }
                                    `}
                                    options             = {positionOption}
                                    isDisabled          = {(!watch("workunit") || positionOption?.length === 0)}
                                    isClearable         = {true}
                                />
                            )}
                        />
                        {errors.position && <small className="text-danger">{errors.position?.value?.message}</small>}
                    </div>

                    <div
                        className   = "mb-1"
                    >
                        <Label>Pangkat</Label>
                        <Controller
                            name        = "grade"
                            control     = {control}
                            render      = {({ field }) => (
                                <Select
                                    {...field} 
                                    className           = {`react-select ${errors.grade?.value && "is-invalid"}`}
                                    classNamePrefix     = "select"
                                    placeholder         = "Pangkat"
                                    options             = {gradeOption}
                                    isClearable         = {true}
                                />
                            )}
                        />
                        {errors.grade && <small className="text-danger">{errors.grade?.value?.message}</small>}
                    </div>

                    <div
                        className   = "mb-1"
                    >
                        <Label>Role</Label>
                        <Controller
                            name        = "role"
                            control     = {control}
                            render      = {({ field }) => (
                                <Select
                                    {...field} 
                                    className           = {`react-select ${errors.role?.value && "is-invalid"}`}
                                    classNamePrefix     = "select"
                                    placeholder         = "Role"
                                    options             = {roleOption}
                                    isClearable         = {true}
                                />
                            )}
                        />
                        {errors.role && <small className="text-danger">{errors.role?.value?.message}</small>}
                    </div>

                    <div
                        className       = "d-flex justify-content-between col-12 border-top pt-1"
                    >
                        <Button
                            color       = "primary"
                            outline
                            onClick     = {() => {
                                onReset()
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            color       = "primary"
                            type        = "submit"
                        >
                            Filter
                        </Button>
                    </div>
                </Form>
		    </ModalFilterConfig>
        </>
    )
}

export default UserManagementFilter