import React, { useEffect, useState } from 'react'
import Avatar from '../../../components/avatar'
import moment from 'moment'
import classNames from 'classnames'
import reportAPI from '../../../../service/report'
import toast from 'react-hot-toast'

const NotificationList = ({
    item, 
    index,
    singleNotificationHasRead
}) => {

    const idPreviewReport = [
        1, 2, 3, 4, 5, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 93,
        24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 94, 95, 96, 83, 84, 85, 86,
        87, 88, 89, 90,
    ]
    
    const getReportId = (data) => {
		const params = {
			id : data?.id ? data?.id : 1
		}
		reportAPI.getReport(params)
			.then((res) => {
                if (item?.notify?.content && res?.results[0]) {
                    if (idPreviewReport.includes(parseInt(res?.results[0]?.report_type_id))) {
                        window.open(`/${res?.results[0]?.report_type?.type?.toString().toLowerCase().replace(/\./g, '')}/${res?.results[0]?.report_type?.seq}?report_id=${res?.results[0]?.id}&action=preview`, '_blank')
                    } else {
                        window.open(`/${res?.results[0]?.report_type?.type?.toString().toLowerCase().replace(/\./g, '')}/${res?.results[0]?.report_type?.seq}?report_id=${res?.results[0]?.id}&action=detail`, '_blank')
                    }
                  } else {
                    toast.error(`Tidak Ada Surat`)
                  }
			})
	}

  return (
    <a
        key			= {index}
        target      = '_blank'
       
        onClick		= {e => {
            if (!item.is_read) {
                singleNotificationHasRead(item.id)
            }
            getReportId({id : item?.notify?.content})
        }}
        className	= {`d-flex ${item.is_read && 'cursor-auto'}`}
    >
        <div
            className={classNames('list-item d-flex', {
                "bg-light-secondary border-light": !item.is_read
            })}
        >
                <>
                    <div className='me-1 d-flex align-items-center'>
                    <Avatar 
                        // onError={fallbackImage_} 
                        img         = {item?.notify?.icon  === "" ? `https://ui-avatars.com/api/?name=${item ? item?.notify?.title : "UN"}&background=4e73df&color=fff&bold=true` : item?.notify?.icon} 
                        imgWidth    = '40'
                        imgHeight   = '40'  
                        // className   = 'my-1'
                    />
                    </div>
                    <div className='list-item-body flex-grow-1'>
                        <div className='d-flex justify-content-between text-primary align-items-center mb-0'>
                            <p className='mb-0'>
                                {item?.notify?.title}
                            </p>
                            <p style={{ fontSize : '10px' }} className='mb-0'>
                                {moment(item.created_at).format('D MMMM YYYY, h:mm')}
                            </p>
                        </div>
                        <p className='notification-text'>{item?.notify?.body}</p>
                    </div>
                </>
                
        </div>
    </a>
  )
}

export default NotificationList