import { 
    useEffect,
    useState
}                           from "react"

import { 
    File,
    Filter,
    Plus, 
    Printer, 
    RotateCcw, 
    Search, 
    Sliders, 
    Trash2 
}                           from "react-feather"

import { 
    Button, 
    FormGroup, 
    Input 
}                           from "reactstrap"

import moment               from "moment"
import DataTable            from "react-data-table-component"
import IcSorting            from "../../../assets/images/icons/svg/IcSorting"

import './index.scss'
import CustomPagination from "../data-tables/custom-paginaton"

const DataTablesGlobal = (props) => {

    const {
        data,
        limit,
        header,
        params,
        getData,
        loading,
        selected,
        totalData,
        setParams,
        visibility,
        setSelected,
        hideCheckbox,
        toggledClearRows,
        fixedHeaderScrollHeight,
        setIsHeaderConfigVisible,
        setIsSortingModalVisible,
        setIsCreateModalVisible,
        setIsDeleteModalVisible,
        setIsRestoreModalVisible,
        setIsFilterModalVisible,
        handlePrint,
        handleExport,
        currentPage,
        setCurrentPage,
        disabledPaginationDefault
    }                           = props

    const [fetchStatus, setFetchStatus] = useState(false)
    const [keyword, setKeyword]         = useState("")

    const conditionalRowStyles = [
        {
            when: row => row.expire_date && moment(row.expire_date).isBefore(),
            classNames: ['custom-bg-danger']
        },
        {
            when: row => row.expire_date &&  moment(row.expire_date).isAfter(),
            classNames: ['custom-bg-success']
        }
    ]

    const paginationComponentOptions = {
        rowsPerPageText     : 'Baris per Halaman :',
        selectAllRowsItem   : false,
        rangeSeparatorText  : 'dari'
    }

    useEffect(() => {
        getData(params)
    }, [])

    useEffect(() => {
        if (fetchStatus) {
            if (params) {
                getData(params)
            }
        }
    }, [params])

    const setVisibilityCheck = () => {
        let show = true

        if (hideCheckbox === true) {
            show = false
        } 

        return show
    }
    
    return (
        <>
            <div
                className = "my-2 h-100"
            >
                <div
                    className   = "mb-1 d-flex justify-content-between"
                >
                    {
                        visibility !== null &&
                        <>
                            <div>
                                {
                                    visibility?.create &&
                                    <Button
                                        size        = "sm"
                                        color       = "primary"
                                        className   = "btn-icon me-1"
                                        onClick     = {() => {
                                            setIsCreateModalVisible(true)
                                            setSelected([])
                                        }}
                                    >
                                        <Plus 
                                            
                                        />
                                    </Button>
                                }

                                {
                                    visibility?.header_config &&
                                    <Button
                                        size        = "sm"
                                        color       = "primary"
                                        className   = "btn-icon me-1"
                                        onClick     = {() => {
                                            setIsHeaderConfigVisible(true)
                                        }}
                                    >
                                        <Sliders />
                                    </Button>
                                }

                                {
                                    visibility?.filter &&
                                    <Button
                                        size        = "sm"
                                        color       = "primary"
                                        className   = "btn-icon me-1"
                                        onClick     = {() => {
                                            setIsFilterModalVisible(true)
                                        }}
                                    >
                                        <Filter 
                                            
                                        />
                                    </Button>
                                }

                                {
                                    visibility?.sorting &&
                                    <Button
                                        size        = "sm"
                                        color       = "primary"
                                        className   = "btn-icon me-1"
                                        onClick     = {() => {
                                            setIsSortingModalVisible(true)
                                        }}
                                    >
                                        <IcSorting 
                                            
                                        />
                                    </Button>
                                }
                                
                            </div>
                            <div className="d-flex justify-content-end">
                                {
                                    visibility?.search &&
                                    <div className="col p-0 m-0 input-group ms-1">
                                        <Input
                                            type        = "text"
                                            placeholder = "Cari Sesuatu.."
                                            onChange    = {(e) => {
                                                setKeyword(e.target.value)
                                            }}
                                        />

                                        <Button
                                            size        = "sm"
                                            color       = "primary"
                                            className   = "btn-icon input-group-append"
                                            onClick     = {() => {
                                                setParams({...params, page : 1, keyword : keyword})
                                                setFetchStatus(true)
                                            }}
                                        >
                                            <Search 
                                                
                                            />
                                        </Button>
                                    </div>
                                }
                                {
                                    visibility?.print &&
                                    <Button
                                        size        = "sm"
                                        color       = "primary"
                                        className   = "btn-icon ms-1"
                                        disabled    = {!data?.length > 0}
                                        onClick     = {() => {
                                            if (handlePrint) {
                                                handlePrint()
                                            }
                                        }}
                                    >
                                        <Printer
                                            
                                        />
                                    </Button>
                                }
                                {
                                    visibility?.export &&
                                    <Button
                                        size        = "sm"
                                        color       = "primary"
                                        className   = "btn-icon ms-1"
                                        disabled    = {!data?.length > 0}
                                        onClick     = {() => {
                                            if (handleExport) {
                                                handleExport()
                                            }
                                        }}
                                    >
                                        <File
                                            
                                        />
                                    </Button>
                                }
                                {
                                    visibility?.delete &&
                                    <Button
                                        size        = "sm"
                                        color       = "primary"
                                        className   = "btn-icon ms-1"
                                        disabled    = {!selected?.length > 0}
                                        onClick     = {() => {
                                            setIsDeleteModalVisible(true)
                                        }}
                                    >
                                        <Trash2
                                            
                                        />
                                    </Button>
                                }
                                {
                                    visibility?.restore &&
                                    <Button
                                        size        = "sm"
                                        color       = "primary"
                                        className   = "btn-icon ms-1"
                                        disabled    = {!selected?.length > 0}
                                        onClick     = {() => {
                                            setIsRestoreModalVisible(true)
                                        }}
                                    >
                                         <RotateCcw /> Kembalikan Data
                                    </Button>
                                }
                                {
                                    visibility?.delete_permanent &&
                                    <Button
                                        size        = "sm"
                                        color       = "primary"
                                        className   = "btn-icon ms-1"
                                        disabled    = {!selected?.length > 0}
                                        onClick     = {() => {
                                            setIsDeleteModalVisible(true)
                                        }}
                                    >
                                        <Trash2 /> Hapus Permanen
                                    </Button>
                                }
                            </div>
                        </>
                    }
                </div>
                <div className="mb-1 bg-dannger">
                    <DataTable
                        data                        = {data}
                        columns                     = {header}
                        selectableRows              = {setVisibilityCheck()}
                        progressPending             = {loading}
                        conditionalRowStyles        = {conditionalRowStyles}
                        paginationComponentOptions  = {paginationComponentOptions}

                        //pagination
                        pagination                  = {!disabledPaginationDefault}
                        paginationServer
                        paginationTotalRows         = {totalData}
                        paginationPerPage           = {limit}
                        paginationDefaultPage       = {1}
                        
                        //handle action
                        onChangeRowsPerPage         = {(res) => {
                            setParams({...params, page_size:res})
                            setFetchStatus(true)
                        }} 
                        onChangePage                = {(res) => {
                            setParams({...params, page:res})
                            setFetchStatus(true)
                        }}
                        selected                    = {selected}
                        onSelectedRowsChange        = {(res) => (setSelected(res.selectedRows))}
                        clearSelectedRows           = {toggledClearRows}

                        // Scroll
                        fixedHeader
                        fixedHeaderScrollHeight     = {fixedHeaderScrollHeight}

                        // other props
                        pointerOnHover
                        highlightOnHover
                    />

                    {
                        disabledPaginationDefault &&
                        <CustomPagination
                            page            = {params?.page ? params?.page : 1}
                            pageSize        = {params?.page_size ? params?.page_size : 10}
                            totalData       = {totalData}
                            params          = {params}
                            setParams       = {setParams}
                            setFetchStatus  = {setFetchStatus}
                            setCurrentPage  = {(new_page) => {
                                setParams({
                                    ...params,
                                    page : new_page
                                })
                            }}
                            loading         = {loading}
                        />
                    }

                </div>
            </div>
        </>
    )
}

export default DataTablesGlobal