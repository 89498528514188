import { Button, Form, Label }      from "reactstrap"
import { Controller, useForm }      from "react-hook-form"

import ModalFilterConfig            from "../../../../@core/components/modal-filter-config"
import Flatpickr 			        from "react-flatpickr"
import classNames from "classnames"
import moment from "moment"

const UserActivityFilter = (props) => {

    const {
        show,
        setShow,
        getData,
        params,
        setParams
    } = props

    const {
		reset,
        watch,
        getValues,
		control,
		setValue,
		setError,
		clearErrors,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode        : "onChange",
    })

    const onSubmit = (values) => {
        const filterParam = {
            time_range      : values?.date_range?.length > 0 ? `${moment(values?.date_range[0]).format("YYYY-MM-DD")},${moment(values?.date_range[1]).format("YYYY-MM-DD")}` : null
        }

        console.log("filterParam", filterParam)

        const newParam = {
            ...params,
            ...filterParam,
            page        : 1,
        }

        setParams(newParam)

        getData(newParam)

        setShow(false)
    }

    const onReset = (values) => {

        const newParam = {
            page        : 1,
        }

        setParams(newParam)

        getData(newParam)

        setShow(false)

        reset()
    }

    return (
        <>
            <ModalFilterConfig
                show        = {show}
                setShow     = {setShow}
                size        = "sm"
                title       = "Filter Log Aktivitas"
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                
                    <div
                        className   = "mb-1"
                    >
                        <Label>Induk</Label>
                        <Controller
                            name        = "date_range"
                            control     = {control}
                            render      = {({ field }) => (
                                <Controller
                                    name        = "date_range"
                                    control     = {control}
                                    render      = {({ field }) => (
                                        <Flatpickr
                                            {...field}
                                            onChange	    = {(date_range) => field.onChange(date_range)}
                                            className		= {classNames("form-control", {
                                                "is-invalid": errors.date_range && true,
                                            })}
                                            options			= {{
                                                mode        : "range",
                                                dateFormat	: "d-m-Y",
                                                time_24hr	: true,
                                                enableTime	: false,
                                                static		: true
                                            }}
                                            id				= "date_range"
                                            placeholder		= "Pilih Rentang Tanggal"
                                            invalid			= {errors.date_range && true}
                                        />
                                    )}
                                />
                            )}
                        />
                        {errors.date_range && <small className="text-danger">{errors.date_range?.message}</small>}
                    </div>

                    <div
                        className       = "d-flex justify-content-between col-12 border-top pt-1"
                    >
                        <Button
                            color       = "primary"
                            outline
                            onClick     = {() => {
                                onReset()
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            color       = "primary"
                            type        = "submit"
                        >
                            Filter
                        </Button>
                    </div>
                </Form>
		    </ModalFilterConfig>
        </>
    )
}

export default UserActivityFilter