import { Button, Form, Label }      from "reactstrap"
import { Controller, useForm }      from "react-hook-form"
import { useContext, useEffect }    from "react"

import { WorkunitContext }          from "../../../../context/workunitContext"
import { RoleContext }              from "../../../../context/roleContext"
import { GradeContext }             from "../../../../context/gradeContext"
import { PositionContext }          from "../../../../context/positionContext"

import Select                       from "react-select"
import ModalFilterConfig            from "../../../../@core/components/modal-filter-config"

const PoisitionFilter = (props) => {

    const {
        show,
        setShow,
        getData,
        params,
        setParams
    } = props

    const {
		reset,
        watch,
        getValues,
		control,
		setValue,
		setError,
		clearErrors,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode        : "onChange",
    })

    const {
        workunitOption
    } = useContext(WorkunitContext)


    const onSubmit = (values) => {
        const filterParam = {
            work_unit    : values?.workunit?.value,
        }

        const newParam = {
            ...params,
            ...filterParam,
            page        : 1,
        }

        setParams(newParam)

        getData(newParam)

        setShow(false)
    }

    const onReset = (values) => {

        const newParam = {
            page        : 1,
        }

        setParams(newParam)

        getData(newParam)

        setShow(false)

        reset()
    }

    return (
        <>
            <ModalFilterConfig
                show        = {show}
                setShow     = {setShow}
                size        = "sm"
                title       = "Filter Jabatan"
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                
                    <div
                        className   = "mb-1"
                    >
                        <Label>Satuan Kerja</Label>
                        <Controller
                            name        = "workunit"
                            control     = {control}
                            render      = {({ field }) => (
                                <Select
                                    {...field} 
                                    className           = {`react-select ${errors.workunit?.value && "is-invalid"}`}
                                    classNamePrefix     = "select"
                                    placeholder         = "Satuan Kerja"
                                    options             = {workunitOption}
                                    isClearable         = {true}
                                />
                            )}
                        />
                        {errors.workunit && <small className="text-danger">{errors.workunit?.value?.message}</small>}
                    </div>

                    <div
                        className       = "d-flex justify-content-between col-12 border-top pt-1"
                    >
                        <Button
                            color       = "primary"
                            outline
                            onClick     = {() => {
                                onReset()
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            color       = "primary"
                            type        = "submit"
                        >
                            Filter
                        </Button>
                    </div>
                </Form>
		    </ModalFilterConfig>
        </>
    )
}

export default PoisitionFilter