import { 
    useContext, 
    useEffect, 
    useState 
}                                   from "react"

import { WorkunitContext }          from "../../../context/workunitContext"

import WorkunitTable                from "./component/WorkunitTable"
import WorkunitCreate               from "./component/WorkunitCreate"
import WorkunitUpdate               from "./component/WorkunitUpdate"
import ModalDelete                  from "../../other/ModalDelete"
import workunitAPI                  from "../../../service/pages/workunit"
import toast                        from "react-hot-toast"
import serviceAPI                   from "../../../service/drive/service"
import WorkunitFilter               from "./component/WorkunitFilter"

const WorkunitConfiguration = () => {   

    const [selected, setSelected]                         = useState([])
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)
    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [isFilterModalVisible, setIsFilterModalVisible] = useState(false)
    const [params, setParams]                             = useState({})

    const {
        getData,
        pagination,

        isLoading,

        listData,
        setListData
    }                                         = useContext(WorkunitContext)

    const [clearSelected, setClearSelected]   = useState(false)

    const handleCreate = async (data, profilePicture) => {
        const payload = {
            name                : data?.name,
            code                : data?.code,
            address             : data?.address,
            phone_number        : data?.phone_number,
            logo                : "-",
            latitude            : data?.latitude,
            longitude           : data?.longitude,
            description         : data?.description,
            work_unit_level_id  : !isNaN(parseInt(data?.work_unit_level_id?.value)) ? parseInt(data?.work_unit_level_id?.value) : 0,
            parent_id           : !isNaN(parseInt(data?.parent?.value)) ? parseInt(data?.parent?.value) : 0
        }

        if (profilePicture) {
            const dataPhoto = new FormData()
            dataPhoto.append("service", "workunit-management")
            dataPhoto.append("file", profilePicture)
      
            const resFoto = await serviceAPI.uploadFileService(dataPhoto)
            
            payload.logo = resFoto.data.url_file
        }

        workunitAPI.createWorkunit(payload)
            .then((res) => {
                toast.success("Satuan Kerja Berhasil Dibuat")
                getData()
                setIsCreateModalVisible(false)
            })
    }

    const handleUpdate = async (data, profilePicture) => {
        const payload = {
            name                : data?.name,
            code                : data?.code,
            address             : data?.address,
            phone_number        : data?.phone_number,
            logo                : data?.logo,
            latitude            : data?.latitude,
            longitude           : data?.longitude,
            description         : data?.description,
            work_unit_level_id  : !isNaN(parseInt(data?.work_unit_level_id?.value)) ? parseInt(data?.work_unit_level_id?.value) : 0,
            parent_id           : !isNaN(parseInt(data?.parent?.value)) ? parseInt(data?.parent?.value) : 0
        }

        if (profilePicture) {
            const dataPhoto = new FormData()
            dataPhoto.append("service", "workunit-management")
            dataPhoto.append("file", profilePicture)
      
            const resFoto = await serviceAPI.uploadFileService(dataPhoto)
            
            payload.logo = resFoto.data.url_file
        }

        workunitAPI.updateWorkunit(payload, selected[0]?.id)
            .then((res) => {
                toast.success("Data Satuan Kerja Berhasil Diubah")
                getData()
                setIsUpdateModalVisible(false)
            })
    }

    const handleDelete = () => {
        if (selected.length > 0) {
            selected.map((data) => (
                workunitAPI.deleteWorkunit(data.id).then(
                    () => {
                        getData(params)
                    }
                ).catch( 
                    err => {
                        console.log(err)
                    }
                )
            ))

            setSelected([])
        }
    }

    useEffect(() => {
        if  (clearSelected) {
            setClearSelected(!clearSelected)
        }
    }, [clearSelected])

    useEffect(() => {
        if (!isUpdateModalVisible) {
            setSelected([])
            setClearSelected(true)
        }
    }, [isUpdateModalVisible])

    useEffect(() => {
        if (!isDeleteModalVisible) {
            setSelected([])
            setClearSelected(true)
        }
    }, [isDeleteModalVisible])

    return (
        <>
            <WorkunitTable
                data                    = {listData}
                params                  = {params}
                setData                 = {setListData}
                getData                 = {getData}
                loading                 = {isLoading}
                selected                = {selected}
                setParams               = {setParams}
                pagination              = {pagination}
                setSelected             = {setSelected}
                clearSelected           = {clearSelected}
                setClearSelected        = {setClearSelected}
                setIsCreateModalVisible = {setIsCreateModalVisible}
                setIsUpdateModalVisible = {setIsUpdateModalVisible}
                setIsDeleteModalVisible = {setIsDeleteModalVisible}
                setIsFilterModalVisible = {setIsFilterModalVisible}
            />

            <WorkunitCreate
                show        = {isCreateModalVisible}
                setShow     = {setIsCreateModalVisible}
                handleCreate= {handleCreate}
                title       = "Tambah Satuan Kerja"
                size        = "lg"
            />

            <WorkunitUpdate
                show        = {isUpdateModalVisible}
                setShow     = {setIsUpdateModalVisible}
                handleUpdate= {handleUpdate}
                title       = "Ubah Satuan Kerja"
                size        = "lg"
                data        = {selected?.length === 1 ? selected[0] : null}
            />

            <ModalDelete
                show        = {isDeleteModalVisible}
                setShow     = {setIsDeleteModalVisible}
                size        = "sm"
                title       = "Hapus Satuan Kerja"
                handleDelete= {handleDelete}
                data        = {selected}
            />

            <WorkunitFilter
                show            = {isFilterModalVisible}
                setShow         = {setIsFilterModalVisible}
                getData         = {getData}
                params          = {params}
                setParams       = {setParams}
            />
        </>
    )
}

export default WorkunitConfiguration