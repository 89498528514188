import { 
    Button,
    Form,
    FormFeedback,
    Input,
    Label, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader 
}                               from "reactstrap"

import { 
    WorkunitContext 
}                               from "../../../../context/workunitContext"

import { 
    useContext, 
    useEffect, 
    useRef,
    useState
}                               from "react"

import { 
    Controller, 
    useForm 
}                               from "react-hook-form"

import { 
    yupResolver 
}                               from "@hookform/resolvers/yup"

import { 
    WorkunitLevelContext 
}                               from "../../../../context/workunitLevelContext"

import WorkunitValidation       from "./WorkunitValidation"
import Select                   from 'react-select'
import baseURL from "../../../../service"

const WorkunitCreate = ({
    show,
    setShow,
    title,
    size,
    data,
    handleUpdate
}) => {

    const {
		reset,
		control,
		setValue,
		setError,
		clearErrors,
        watch,
        getValues,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode        : "onChange",
		resolver    : yupResolver(WorkunitValidation),
	})

    const fileInputRef = useRef(null)

    const [profilePicture, setProfilePicture]             = useState(null)
    const [profilePictureBase64, setProfilePictureBase64] = useState(null)

    const {
        workunitLevelOption
    } = useContext(WorkunitLevelContext)

    const {
        workunitOption
    } = useContext(WorkunitContext)

    const onSubmit = (data) => {
        handleUpdate(data, profilePicture)
    }

    const handleFileSelect = () => {
        fileInputRef.current.click()
    }

    useEffect(() => {
        // if (workunitOption?.length > 0) {
        //     workunitOption.map((dt) => {
        //         const selected = dt?.options?.filter((dt) => dt.value === data?.parent_id)
        //         if (selected?.length > 0) {
        //             console.log("workunitOption123_value", selected)
        //             // setValue("parent", selected[0])
        //         }
        //     })
        // }

        setValue("parent", {
            label   : data?.parent,
            value   : data?.parent_id
        })

        if (workunitLevelOption?.length > 0) {
            const selected = workunitLevelOption?.filter((dt) => dt.value === data?.work_unit_level_id)
            if (selected?.length > 0) {
                setValue("work_unit_level_id", selected[0])
            }
        }

        setProfilePictureBase64(`${baseURL.replace("/api", "")}${data?.logo}`)
    }, [data])

    return (
        <>
            <Modal
                isOpen  = {show} 
                toggle  = {() => { setShow(!show) }} 
                size    = {size || "sm"}
            >
                <ModalHeader 
                    toggle={() => setShow(!show)} 
                > 
                    {title}
                </ModalHeader>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody
                        className="d-flex"
                    >
                        <div
                            className="col-6 pe-1"
                        >
                            <div
                                className   = "mb-1 m-0 p-0"
                            >
                                <Label>Logo</Label>
                                <div
                                    className = ""
                                >
                                    <img
                                        src         = {profilePictureBase64 ? profilePictureBase64 : `https://ui-avatars.com/api/?name=${getValues('name') ? watch("name") : "-"}&background=4e73df&color=fff&bold=true`}
                                        width       = "113"
                                        height      = "113"
                                        className   = "rounded mr-50"
                                    />

                                    <Button.Ripple
										color		= "primary"
                                        className   = "ms-1"
                                        onClick     = {handleFileSelect}
									>
					    				Pilih Foto
    								</Button.Ripple>

                                    <input
                                        type            = "file"
                                        accept          = "image/*"
                                        ref             = {fileInputRef}
                                        style           = {{ display: 'none' }}
                                        onChange        = {(e) => {
                                            const selectedFile = e.target.files[0]
                                            setProfilePicture(selectedFile)

                                            const reader = new FileReader()
                                            reader.onload = () => {
                                                setProfilePictureBase64(reader.result)
                                            }
                                            reader.readAsDataURL(selectedFile)
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                className   = "mb-1"
                            >
                                <Label>Induk</Label>
                                <Controller
                                    name        = "parent"
                                    control     = {control}
                                    render      = {({ field }) => (
                                        <Select
                                            {...field} 
                                            className           = {`react-select ${errors.parent?.value ? "is-invalid" : ""}`}
                                            classNamePrefix     = "select"
                                            placeholder         = "Induk"
                                            options             = {workunitOption}
                                        />
                                    )}
                                />
                                {errors.parent?.value && <FormFeedback>{errors.parent?.value?.message}</FormFeedback>}
                            </div>
                            <div
                                className   = "mb-1"
                            >
                                <Label>ID</Label>
                                <Controller
                                    name        = "code"
                                    control     = {control}
                                    render      = {({ field }) => (
                                        <Input
                                            {...field} 
                                            invalid             = {errors.code && true}
                                            placeholder         = "ID"
                                            defaultValue        = {data?.code} 
                                        />
                                    )}
                                    defaultValue={data?.code}
                                />
                                {errors.code && <FormFeedback>{errors.code.message}</FormFeedback>}
                            </div>
                            <div
                                className   = "mb-1"
                            >
                                <Label>No. Telepon</Label>
                                <Controller
                                    name        = "phone_number"
                                    control     = {control}
                                    render      = {({ field }) => (
                                        <Input
                                            {...field} 
                                            invalid             = {errors.phone_number && true}
                                            placeholder         = "No. Telepon"
                                            defaultValue        = {data?.phone_number} 
                                        />
                                    )}
                                    defaultValue={data?.phone_number}
                                />
                                {errors.phone_number && <FormFeedback>{errors.phone_number.message}</FormFeedback>}
                            </div>
                            <div
                                className   = "mb-1"
                            >
                                <Label>Satuan Kerja</Label>
                                <Controller
                                    name        = "name"
                                    control     = {control}
                                    render      = {({ field }) => (
                                        <Input
                                            {...field} 
                                            invalid             = {errors.name && true}
                                            placeholder         = "Nama"
                                            defaultValue        = {data?.name} 
                                            name                = "name"
                                        />
                                    )}
                                    defaultValue={data?.name}
                                />
                                {errors.name && <FormFeedback>{errors.name.message}</FormFeedback>}
                                
                            </div>
                        </div>
                        <div className="col-6 ps-1">
                            <div
                                className   = "mb-1"
                            >
                                <Label>Pilih Tingkat Satuan Kerja</Label>
                                <Controller
                                    name        = "work_unit_level_id"
                                    control     = {control}
                                    render      = {({ field }) => (
                                        <Select
                                            {...field} 
                                            className           = {`react-select ${errors.work_unit_level_id?.value ? "is-invalid" : ""}`}
                                            classNamePrefix     = "select"
                                            placeholder         = "Pilih Tingkat Satuan Kerja"
                                            options             = {workunitLevelOption}
                                        />
                                    )}
                                />
                                {errors.work_unit_level_id?.value && <FormFeedback>{errors.work_unit_level_id?.value.message}</FormFeedback>}
                            </div>
                            <div
                                className   = "mb-1"
                            >
                                <Label>Alamat</Label>
                                <Controller
                                    name        ="address"
                                    control     ={control}
                                    render      ={({ field }) => (
                                        <Input
                                            {...field} 
                                            invalid             = {errors.address && true}
                                            type                = "textarea"
                                            placeholder         = "Alamat"
                                        />
                                    )}
                                    defaultValue={data?.address}
                                />
                                {errors.address && <FormFeedback>{errors.address.message}</FormFeedback>}
                            </div>
                            <div
                                className   = "mb-1"
                            >
                                <Label>Garis Lintang</Label>
                                <Controller
                                    name        ="latitude"
                                    control     ={control}
                                    render      ={({ field }) => (
                                        <Input
                                            {...field} 
                                            invalid             = {errors.latitude && true}
                                            type                = "number"
                                            placeholder         = "Garis Lintang"
                                        />
                                    )}
                                    defaultValue={data?.latitude}
                                />
                                {errors.latitude && <FormFeedback>{errors.latitude.message}</FormFeedback>}
                            </div>
                            <div
                                className   = "mb-1"
                            >
                                <Label>Garis Bujur</Label>
                                <Controller
                                    name        ="longitude"
                                    control     ={control}
                                    render      ={({ field }) => (
                                        <Input
                                            {...field} 
                                            invalid             = {errors.longitude && true}
                                            type                = "number"
                                            placeholder         = "Garis Bujur"
                                        />
                                    )}
                                    defaultValue={data?.longitude}
                                />
                                {errors.longitude && <FormFeedback>{errors.longitude.message}</FormFeedback>}
                            </div>
                            <div
                                className   = "mb-1"
                            >
                                <Label>Deskripsi</Label>
                                <Controller
                                    name        ="description"
                                    control     ={control}
                                    render      ={({ field }) => (
                                        <Input
                                            {...field} 
                                            invalid             = {errors.description && true}
                                            type                = "textarea"
                                            placeholder         = "Deskripsi"
                                        />
                                    )}
                                    defaultValue={data?.description}
                                />
                                {errors.description && <FormFeedback>{errors.description.message}</FormFeedback>}
                            </div>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <div
                            className   = "d-flex justify-content-between col-12"
                        >
                            <Button
                                color   = "primary"
                                outline
                                onClick = {() => {
                                    setShow(false)
                                }}
                            >
                                Batal
                            </Button>

                            <Button
                                color   = "primary"
                                type    = "submit"
                            >
                                Simpan
                            </Button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    )
}

export default WorkunitCreate