import { 
    useState,
    createContext,
    useEffect
}                       from "react"

import reportAPI        from "../service/report"
import featureAPI       from "../service/pages/feature"
import toast            from "react-hot-toast"

//Context
const RoleContext       = createContext(null)

const _tempFeatureIds   = []
let createAction        = () => {}
let canCreate           = true

const RoleProvider = ({ children }) => {

    //State
    const [reportType, setReportType]   = useState([])
    const [roleData, setRoleData]       = useState([])
    const [pagination, setPagination]   = useState(false)
    const [isLoading, setIsLoading]     = useState(null)

    const [roleOptionDetault, setRoleOptionDetault]   = useState([
        {
            label : "Operator",
            value : 1
        }, {
            label : "Pimpinan Pusat",
            value : 2
        }, {
            label : "Pimpinan Daerah",
            value : 3
        }, {
            label : "Pelaksana",
            value : 4
        }
    ])

    const [roleOption, setRoleOption]   = useState([])

    const [reload, setReload]               = useState(false)
    const [payload, setPayload]             = useState(null)
    const [activeRoleId, setActiveRoleId]   = useState(null)
    const [featureIds, setFeatureIds]       = useState([])
    const [menus, setMenus]                 = useState([])

    const getReportType = () => {
        reportAPI.getReportTypeAll({
            page_size : 100
        })
            .then((res) => {
                const temp        = []
                res?.results?.map((dt) => {
                    temp.push({
                        report_type             : dt.id,
                        type                    : dt.type,
                        name                    : dt.name,
                        description             : dt.description,
                        can_create              : false,
                        can_read                : false,
                        can_update              : false,
                        can_delete              : false,
                        can_request             : false,
                        can_approve_or_reject   : false
                    })
                })

                const _reportType = [
                    {
                        name    : "IN. SURAT",
                        report  : []
                    },
                    {
                        name    : "L.IN LAPORAN",
                        report  : []
                    },
                    {
                        name    : "R.IN REGISTER",
                        report  : []
                    },
                    {
                        name    : "D.IN DATA",
                        report  : []
                    },
                ]

                _reportType[0].report = temp.filter((dt) => dt.type === "IN")
                _reportType[1].report = temp.filter((dt) => dt.type === "L.IN")
                _reportType[2].report = temp.filter((dt) => dt.type === "R.IN")
                _reportType[3].report = temp.filter((dt) => dt.type === "D.IN")

                setReportType(_reportType)
            })
    }

    const getData = (param) => {
        setIsLoading(true)

        param = {...param, order_by : "-id"}
        featureAPI.getRole(param)
            .then((res) => {
                setIsLoading(false)

                const resulsts = []
                res.results.map((dt, index) => {
                    dt.index = index + 1
                    resulsts.push(dt)
                })
                setRoleData(res.results)

                const _pagination = {
                    count       : res.count,
                    page        : res.page,
                    page_size   : res.page_size,
                    next        : res.next,
                    previous    : res.previous
                }
                setPagination(_pagination)
            })
    }

    const getMenus = (role_id) => {
        setMenus([])

        const params = {
            parent      : true,
            page_size   : 15
        }

        if (role_id) {
            params.role_id = role_id
            params.sidebar = true
        }

        featureAPI.getMenusAll(params)
            .then((res) => {
                const temp = []
                res.results?.map((dt) => {
                    const tempChild = []
                    dt.children?.map((dtChild) => {
                        tempChild.push({
                            name    : dtChild.name,
                            id      : dtChild.id,
                            expand  : false,
                            checked : dtChild.is_active,
                            children: []
                        })
                    })
                    temp.push({
                        name    : dt.name,
                        id      : dt.id,
                        expand  : tempChild?.length > 0,
                        checked : dt.is_active,
                        children: tempChild
                    })
                })
                setMenus(temp)
            })
    }

    const getDataRoleOption = () => {
        const params = {
            page        : 1,
            page_size   : 100,
            order_by    : "-id"
        }
        
        featureAPI.getRole(params)
            .then((res) => {
                const resulsts = []
                res.results.map((dt, index) => {
                    dt.index = index + 1
                    resulsts.push({
                        label : dt?.name,
                        value : dt?.id
                    })
                })
                setRoleOption(resulsts)
            })
    }

    const createFeature = (data, roleIdOption, roleId) => {
        const payload = {
            report_type             : data.report_type,
            menu                    : data?.menu,
            name                    : `${data.report_type ? data.report_type : data?.menu}-${roleIdOption}-${roleId}`,
            can_create              : data.can_create,
            can_read                : data.can_read,
            can_update              : data.can_update,
            can_delete              : data.can_delete,
            can_request             : data.can_request,
            can_approve_or_reject   : data.can_approve_or_reject
        }

        featureAPI.createFeature(payload)
            .then((res) => {
                _tempFeatureIds.push(res.id)
                setFeatureIds(_tempFeatureIds)
                setReload(true)
            })
            .catch(() => {
                _tempFeatureIds.push(0)
                setFeatureIds(_tempFeatureIds)
                setReload(true)
            })
    }

    const handleChangeMenuStatus = (id, role_id, is_active) => {
        const payload = {
            role_id   : role_id,
            is_active : is_active
        }
        featureAPI.updateMenuStatus(id, payload)
            .then((res) => {
                console.log("handleChangeMenuStatus", res)
            })
    }

    const handleChangeMenuStatusBulk = (payload) => {
        featureAPI.updateMenuStatusBulk(0, payload)
            .then((res) => {
                console.log("handleChangeMenuStatus", res)
            })
    }

    const createRole = (_payload, feature, menus, _action) => {
        setPayload(_payload)

        createAction = _action
        canCreate    = true

        featureAPI.createRole(_payload)
            .then((res) => {
                setActiveRoleId(res?.id)

                feature.map((dt) => {
                    createFeature(dt, _payload.role, res?.id)
                })

                // menus.map((dt) => {
                //     handleChangeMenuStatus(dt.id, res?.id, dt.checked)

                //     dt.children.map((dtChild) => {
                //         handleChangeMenuStatus(dtChild.id, res?.id, dtChild.checked)
                //     })
                // })

                const tempMenusStatus = []
                menus.map((dt) => {
                    tempMenusStatus.push({
                        is_active : dt.checked,
                        menu_id   : dt.id,
                        role_id   : res?.id
                    })

                    dt.children.map((dtChild) => {
                        tempMenusStatus.push({
                            is_active : dtChild.checked,
                            menu_id   : dtChild.id,
                            role_id   : res?.id
                        })
                    })
                })

                handleChangeMenuStatusBulk(tempMenusStatus)
            })
    }

    const updateFeature = (data, roleIdOption, featureId, roleId) => {

        const payload = {
            report_type             : data.report_type,
            menu                    : data?.menu,
            name                    : `${data.report_type ? data.report_type : data?.menu}-${roleIdOption}-${roleId}`,
            can_create              : data.can_create,
            can_read                : data.can_read,
            can_update              : data.can_update,
            can_delete              : data.can_delete,
            can_request             : data.can_request,
            can_approve_or_reject   : data.can_approve_or_reject
        }

        featureAPI.updateFeature(featureId, payload)
            .then(() => {
            })
    }

    const updateRole = (payload, feature, menus, roleId, updateAction) => {
        const featureIds = []
        
        feature.map((dt) => {
            featureIds.push(dt.id)
            updateFeature(dt, payload.role, dt.id, roleId)
        })

        const tempMenusStatus = []
        menus.map((dt) => {
            tempMenusStatus.push({
                is_active : dt.checked,
                menu_id   : dt.id,
                role_id   : roleId
            })
            // handleChangeMenuStatus(dt.id, roleId, dt.checked)

            dt.children.map((dtChild) => {
                tempMenusStatus.push({
                    is_active : dtChild.checked,
                    menu_id   : dtChild.id,
                    role_id   : roleId
                })
                // handleChangeMenuStatus(dtChild.id, roleId, dtChild.checked)
            })
        })

        handleChangeMenuStatusBulk(tempMenusStatus)

        // console.log("tempMenusStatus", tempMenusStatus)

        payload.feature_ids = featureIds

        featureAPI.updateRole(roleId, payload)
            .then(() => {
                toast.success("Role Berhasil Diubah!")
                updateAction()
            })
    }

    const updateRoleStatus = (roleId, payload, updateAction) => {
        featureAPI.updateRole(roleId, payload)
            .then(() => {
                updateAction()
            })
    }

    useEffect(() => {
        if (reload) {
            setReload(false)

            let length = 0
            reportType.map((data) => {
                data.report.map(() => {
                    length += 1
                })
            })

            if (featureIds?.length === length + 1) {
                const _payload = {...payload, feature_ids : featureIds}
                
                if (canCreate) {
                    canCreate = false
                    featureAPI.updateRole(activeRoleId, _payload)
                        .then(() => {
                            toast.success("Role Berhasil Dibuat!")
                            createAction()
                        })
                }
            }
        }
    }, [reload])

    useEffect(() => {
        if (localStorage.getItem('userData')) {
            getReportType()
            getMenus()
            getDataRoleOption()
        }

    }, [])

    return <RoleContext.Provider
        value={{
            roleOption,
            setRoleOption,
            roleOptionDetault, 
            setRoleOptionDetault,
            roleData,
            setRoleData,
            menus, 
            setMenus,
            isLoading, 
            setIsLoading,

            reportType,
            pagination,
            updateRoleStatus,

            getData,
            getDataRoleOption,
            createRole,
            updateRole,
            getMenus
        }}
    > 
        {children}
    </RoleContext.Provider>
}

export { RoleContext, RoleProvider }