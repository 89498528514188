import { 
    Button,
    Col,
    Input,
    InputGroup, 
    Label, 
    Modal, 
    ModalBody, 
    ModalFooter, 
    ModalHeader, 
    Row
}                           from "reactstrap"

import { 
    useEffect,
    useState 
}                           from "react"

import { Check, Plus, X }   from "react-feather"
import defaultPhoto         from "../../../../assets/images/avatars/2.png"
import Select               from 'react-select'
import Flatpickr            from "react-flatpickr"

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import AssessmentIndicatorAPI from "../../../../service/pages/assesment-indicator"
import serviceAPI from "../../../../service/drive/service"
import toast from "react-hot-toast"
import ModalBadge from "./ModalBadge"
import LabelRequired from "../../../../@core/components/label-required"

const schema = yup.object().shape({
    origin: yup.object().shape({
        value: yup.string().required('Isian Tidak Boleh Kosong'),
        label: yup.string().required('Isian Tidak Boleh Kosong'),
      }),
    title: yup.string().required('Isian Tidak Boleh Kosong'),
    note: yup.string().required('Isian Tidak Boleh Kosong'),
    condition: yup.object().shape({
        value: yup.string().required('Isian Tidak Boleh Kosong'),
        label: yup.string().required('Isian Tidak Boleh Kosong'),
      }),
    target_value: yup.string().required('Isian Tidak Boleh Kosong'),
    performance_point: yup.string().required('Isian Tidak Boleh Kosong'),
    exp_point: yup.string().required('Isian Tidak Boleh Kosong'),
  })

const EvaluationIndicatorCreatePerformance = ({
    show,
    setShow,
    title,
    getData,
    size,
    data
}) => {

    // const { workunits }             = useContext(WorkunitContext)

    const [file, setFile]                       = useState(false) 
    const [image, setImage]                     = useState(false)
    const [showBadge, setShowBadge]             = useState(false) 
    const [listBadge, setListBadge]             = useState(false) 
    const [originOption, setOriginOption]       = useState([])
    const [existingBadge, setExistingBadge]     = useState(false) 


    const relationOptions = [
        { value: '>', label: 'Lebih dari' },
        { value: '<', label: 'Kurang dari' },
        { value: '>=', label: 'Lebih dari sama dengan' },
        { value: '<=', label: 'Kurang dari sama dengan' }
    ]


   const getBadge = () => {
        const params = {
            service_name : 'performance',
            page_size : 100
        }
        serviceAPI.getFileService(params)
        .then((res) => {
            setListBadge(res.results)
        }).catch(() => {
            toast.error('Gagal Mendapatkan Badge')
        })
   }

   const getOrigin = () => {
        AssessmentIndicatorAPI.getDataOrigin()
            .then((res) => {
                
                const formatData_ = []

                    res.results.map((data) => {
                        formatData_.push({
                            label : data?.name,
                            value : data?.id,
                        })
                    })

                    setOriginOption(formatData_)
            })
   }

    const { handleSubmit, setValue, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
      })

      const handleCreate = (data) => {

        if (existingBadge) {
            const formData = {
                origin            : data.origin.value,
                kind              :'personal',
                title             : data?.title,
                condition         : data?.condition.value,
                target_value      : data?.target_value,
                performance_point : data?.performance_point,
                exp_point         : data?.exp_point,
                attachment        : image,
                is_event          : false,
                note              : data?.note
            }
            AssessmentIndicatorAPI.createData('badge', formData)
                .then(() =>  {
                    toast.success('Berhasil Menambahkan Badge')
                    getData()
                    setShow(false)
                    setExistingBadge(false)
                })
                .catch(() => {
                    toast.error('Gagal Menambahkan Badge')
                })
        } else if (file) {
            const fileForm = new FormData()
            fileForm.append('file', file)
            fileForm.append('service', 'performance')

            serviceAPI.uploadFileService(fileForm)
                .then((res) => {
                    const formData = {
                        origin            : data.origin.value,
                        kind              :'personal',
                        title             : data?.title,
                        condition         : data?.condition.value,
                        target_value      : data?.target_value,
                        performance_point : data?.performance_point,
                        exp_point         : data?.exp_point,
                        attachment        : window.location.origin + res.data.url_file,
                        is_event          : false,
                        note              : data?.note
                    }
                    AssessmentIndicatorAPI.createData('badge', formData)
                        .then(() =>  {
                            toast.success('Berhasil Menambahkan Badge')
                            getData()
                            setShow(false)
                        })
                        .catch(() => {
                            toast.error('Gagal Menambahkan Badge')
                        })
                }).catch((err) => {
                    console.log(err)
                    toast.error('Gagal Menambahkan File Badge')
                })
        } else {
            toast.error('Badge Belum Di Pilih')
        }
     
    }

    const handleUpdate = (data, id) => {

        if (existingBadge) {
            const formData = {
                origin            : data.origin.value,
                kind              :'personal',
                title             : data?.title,
                condition         : data?.condition.value,
                target_value      : data?.target_value,
                performance_point : data?.performance_point,
                exp_point         : data?.exp_point,
                attachment        : image,
                is_event          : false,
                note              : data?.note
            
            }
            AssessmentIndicatorAPI.editData('badge', id, formData)
                .then(() =>  {
                    toast.success('Berhasil Edit Badge')
                    getData()
                    setShow(false)
                })
                .catch((err) => {
                    console.log(err)
                    toast.error('Gagal Edit Badge')
                })
        } else if (file) {
            const fileForm = new FormData()
            fileForm.append('file', file)
            fileForm.append('service', 'performance')

            serviceAPI.uploadFileService(fileForm)
                .then((res) => {
                    const formData = {
                        origin            : data.origin.value,
                        kind              :'personal',
                        title             : data?.title,
                        condition         : data?.condition.value,
                        target_value      : data?.target_value,
                        performance_point : data?.performance_point,
                        exp_point         : data?.exp_point,
                        attachment        : window.location.origin + res.data.url_file,
                        is_event          : false,
                        note              : data?.note
                    
                    }
                    AssessmentIndicatorAPI.editData('badge', id, formData)
                        .then(() =>  {
                            toast.success('Berhasil Edit Badge')
                            getData()
                            setShow(false)
                        })
                        .catch((err) => {
                            console.log(err)
                            toast.error('Gagal Edit Badge')
                        })
                }).catch((err) => {
                    console.log(err)
                    toast.error('Gagal Edit File Badge')
                })
        } else {
            toast.error('Badge Belum Di Pilih')
        }
    }

    const onSubmit = (value) => {
        if (data) {
            handleUpdate(value, data?.id)
        } else {
            handleCreate(value)
        }
    }

    useEffect(() => {
        getBadge()
        getOrigin()
    }, [])

    useEffect(() => {
        if (data) {
            setValue('title', data?.title)
            setValue('note', data?.note)
            setValue('target_value', data?.target_value)
            setValue('performance_point', data?.performance_point)
            setValue('exp_point', data?.exp_point)
            setValue('condition', {
                value : data?.condition, 
                label : data?.condition === '>' ? "Lebih Dari" : data?.condition === '<' ? "Kurang Dari" : data?.condition === '<=' ? "Kurang Dari Sama Dengan" : "Lebih Dari Sama Dengan"
            })
            setValue('origin', {
                value : data?.origin, 
                label : data?.origin === 1 ? 
                    "Jumlah Tepat Waktu Laphastug"
                : data?.origin === 2 ? 
                    "Jumlah Tepat Waktu Laopsin"
                : data?.origin === 3 ? 
                    "Jumlah Tepat Waktu Perpanjangan Laphastug"
                : data?.origin === 4 ? 
                    "Jumlah Tepat Waktu Perpanjangan Laopsin"
                : data?.origin === 5 ? 
                    "Jumlah Bintang 3 Laphastug"
                : data?.origin === 6 ? 
                    "Jumlah Bintang 4 Laphastug"
                : data?.origin === 7 ? 
                    "Jumlah Bintang 5 Laphastug"
                : data?.origin === 8 ? 
                    "Jumlah Bintang 3 Laopsin"
                : data?.origin === 9 ? 
                    "Jumlah Bintang 4 Laopsin"
                :
                    "Jumlah Bintang 5 Laopsin"
            })
            setImage(data.attachment)
            setExistingBadge(true)
        }
    }, [data])

    return (
        <>
            <Modal
                isOpen  = {show} 
                toggle  = {() => { setShow(!show) }} 
                size    = {size || "sm"}
            >
                <ModalHeader 
                    toggle={() => setShow(!show)} 
                > 
                    {title}
                </ModalHeader>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody>
                        {/* TITLE AND DESCRIPTION */}
                        <div>
                            <div
                                className   = "d-flex justify-content-between mb-1"
                            >
                                <div className="col-6 pe-1">
                                    <Label><small>Judul</small>  <LabelRequired /></Label>
                                    <Controller
                                        name="title"
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                type="text"
                                                id="title"
                                                {...field}
                                            />
                                        )}
                                    />
                                {
                                    errors && errors.title && 
                                        <small style={{ color: 'red' }}>
                                            {errors.title.message}
                                        </small>
                                }
                                </div>

                                <div className="col-6 ps-1">
                                    <Label><small>Keterangan</small> <LabelRequired /></Label>
                                    <Controller
                                        name="note"
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                type="text"
                                                id="note"
                                                {...field}
                                            />
                                        )}
                                    />
                                {
                                    errors && errors.note && 
                                        <small style={{ color: 'red' }}>
                                            {errors.note.message}
                                        </small>
                                }
                                </div>
                            </div>
                        </div>

                        <div className="border rounded p-1 mb-1">
                            <Row>
                                <Col xs={4} className="d-flex align-items-center justify-content-between ">
                                    <strong>JIKA</strong>
                                    <div>
                                        <small>Pilih Tindakan <LabelRequired /></small>
                                        <div>
                                            <Controller
                                                name="origin"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        id="origin"
                                                        classNamePrefix     = "select"
                                                        placeholder         = "Pilih Tindakan"
                                                        {...field}
                                                        options             = {originOption}
                                                        // value               = {dt.level}
                                                        // onChange            = {(e) => {
                                                        //     dt.level        = e
                                                        //     handleUpdateThen()
                                                        // }}
                                                    />
                                                )}
                                            />
                                            {
                                                errors && errors.origin && 
                                                    <small style={{ color: 'red' }}>
                                                        {errors.origin.label.message}
                                                    </small>
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={4}>
                                    <small>Pilih Relasi <LabelRequired /></small>
                                    <Controller
                                        name="condition"
                                        control={control}
                                        render={({ field }) => (
                                           
                                            <Select
                                                id="condition"
                                                className           = "react-select"
                                                classNamePrefix     = "select"
                                                placeholder         = "Relasi"
                                                {...field}
                                                options             = {relationOptions}
                                                // value               = {dt.level}
                                                // onChange            = {(e) => {
                                                //     dt.level        = e
                                                //     handleUpdateThen()
                                                // }}
                                            />
                                        )}
                                    />
                                    {
                                        errors && errors.condition && 
                                            <small style={{ color: 'red' }}>
                                                {errors.condition.label.message}
                                            </small>
                                    }
                                </Col>
                                <Col xs={4}>
                                    <small>Target Value <LabelRequired /></small>
                                    <Controller
                                        name="target_value"
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                type="number"
                                                id="target_value"
                                                {...field}
                                            />
                                        )}
                                    />
                                    {
                                    errors && errors.target_value && 
                                        <small style={{ color: 'red' }}>
                                            {errors.target_value.message}
                                        </small>
                                }
                                </Col>
                            </Row>
                            <Row className="mt-1">
                                <Col xs={4} className="d-flex align-items-center justify-content-between ">
                                    <strong>MAKA</strong>
                                    <div className="ms-1">
                                        <small>Point <LabelRequired /></small>
                                        <Controller
                                            name="performance_point"
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    type="number"
                                                    id="performance_point"
                                                    {...field}
                                                />
                                            )}
                                        />
                                         {
                                            errors && errors.performance_point && 
                                                <small style={{ color: 'red' }}>
                                                    {errors.performance_point.message}
                                                </small>
                                        }
                                    </div>
                                </Col>
                                <Col xs={4} className="d-flex align-items-center justify-content-between ">
                                    <div className="ms-1">
                                        <small>Exp Point <LabelRequired /></small>
                                        <Controller
                                            name="exp_point"
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    type="number"
                                                    id="exp_point"
                                                    {...field}
                                                />
                                            )}
                                        />
                                        {
                                            errors && errors.exp_point && 
                                                <small style={{ color: 'red' }}>
                                                    {errors.exp_point.message}
                                                </small>
                                        } 
                                    </div>
                                </Col>
                                <Col xs={2}>
                                    <div className="cursor-pointer" onClick = {() => setShowBadge(true)}>
                                        <img src={image} className="rounded-circle bg-primary " height={100} width={100}/>
                                    </div>
                                </Col>
                              
                            </Row>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <div
                            className   = "d-flex justify-content-between col-12"
                        >
                            <Button
                                color   = "primary"
                                outline
                                onClick = {() => {
                                    setShow(false)
                                }}
                            >
                                Batal
                            </Button>

                            <Button
                                color   = "primary"
                                type="submit"
                            >
                                Simpan
                            </Button>
                        </div>
                    </ModalFooter>

                </form>
               
            </Modal>
            <ModalBadge 
                size                = {'md'}
                show                = {showBadge}
                title               = {"Pilih Badge"}
                image               = {image}
                setImage            = {setImage}
                setFile             = {setFile}
                setShow             = {setShowBadge}
                listBadge           = {listBadge}
                setExistingBadge    = {setExistingBadge}
                
            />
        </>
    )
}

export default EvaluationIndicatorCreatePerformance