import { useEffect, useState } from "react"
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from "react-feather"
import { Button, Col, Input } from "reactstrap"

const CustomPagination = (props) => {

    const {
        page,
        pageSize,
        totalData,
        params,
        setParams,
        setFetchStatus,
        setCurrentPage,
        loading
    } = props

    const pageSizeOption = [10, 15, 20, 25, 30]

    const [hoveredButton, setHoveredButton] = useState(null)

    const handleChangePage = (new_page) => {
        setParams({...params, page:new_page}, setFetchStatus(true), setCurrentPage(new_page)) 
    }

    const buttonStyle = (index, disabled) => ({
        borderRadius        : 0,
        backgroundColor     : hoveredButton === index ? "lightgray" : "white", // Warna background saat hover menjadi abu-abu
        color               : disabled ? "gray" : "#555555", // Warna teks saat hover menjadi putih
        padding             : "40px 40px",
        cursor              : "pointer",
        transition          : "background-color 0.3s, color 0.3s", // Animasi transisi
    })

    return (
        <>
            <small>
                <Col 
                    md          = {12}
                    className   = {`d-flex bg-white border-top p-1 text-gray ${loading ? "d-none" : ""}`}
                >
                    <Col
                        md          = {12}
                        className   = "d-flex justify-content-end align-items-center"
                    >
                        <div className="">
                            Baris Per Halaman : 
                            <Input
                                type        = "select" 
                                id          = "pageSize" 
                                value       = {pageSize} 
                                style       = {{ width: "60px", display: "inline-block" }}
                                className   = "ms-1 p-0 border-0 cursor-pointer"
                                onChange    = {(e) => {
                                    // console.log("value1234", value)
                                    setParams({...params, page: 1, page_size:e?.target?.value}, setFetchStatus(true))
                                    setCurrentPage(1)
                                }}
                            >
                                {
                                    pageSizeOption?.map((value) => {
                                        return (
                                            <option value={value}>{value}</option>
                                        )
                                    })
                                }
                            </Input>
                        </div>

                        <div className="px-1">
                            {page ? ((page - 1) * pageSize) + 1 : 1} - {pageSize ? page * pageSize : 10} dari {totalData}
                        </div>

                        <div className="">
                            <Button
                                color       = "white"
                                className   = "p-0 mr-1"
                                size        = "sm"
                                disabled    = {page === 1}
                                onClick     = {() => {
                                    handleChangePage(1)
                                }}
                                style       = {buttonStyle(1, (page === 1))} 
                                onMouseEnter= {() => setHoveredButton(1)}
                                onMouseLeave= {() => setHoveredButton(null)}
                            >
                                <ChevronsLeft />
                            </Button>

                            <Button
                                color       = "white"
                                className   = "p-0 mx-1 border-0"
                                size        = "sm"
                                disabled    = {page === 1}
                                onClick     = {() => {
                                    handleChangePage(page - 1)
                                }}
                                style       = {buttonStyle(2, (page === 1))} 
                                onMouseEnter= {() => setHoveredButton(2)}
                                onMouseLeave= {() => setHoveredButton(null)}
                            >
                                <ChevronLeft />
                            </Button>

                            <Button
                                color       = "white"
                                className   = "p-0 mx-1"
                                disabled    = {(page * pageSize) >= totalData}
                                onClick     = {() => {
                                    handleChangePage(page + 1, ((page * pageSize) >= totalData))
                                }}
                                style       = {buttonStyle(3)} 
                                onMouseEnter= {() => setHoveredButton(3)}
                                onMouseLeave= {() => setHoveredButton(null)}
                            >
                                <ChevronRight />
                            </Button>

                            <Button
                                color       = "white"
                                className   = "p-0 ml-1"
                                disabled    = {(page * pageSize) >= totalData}
                                onClick     = {() => {
                                    handleChangePage(Math.ceil(totalData / pageSize))
                                }}
                                style       = {buttonStyle(4, (page * pageSize) >= totalData)} 
                                onMouseEnter= {() => setHoveredButton(4)}
                                onMouseLeave= {() => setHoveredButton(null)}
                            >
                                <ChevronsRight />
                            </Button>
                        </div>
                    </Col>
                </Col>
            </small>
        </>
    )
}

export default CustomPagination