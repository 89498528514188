import { useContext, useEffect, useState }    	from "react"
import { useNavigate }						 	from "react-router-dom"
import { WorkunitContext } 						from "../../../../context/workunitContext"

// Custom Component
import DropdownTableGlobal                      from "../../../../@core/components/data-tables-global/dropdown"
import DataTablesGlobal                         from "../../../../@core/components/data-tables-global"
import ModalField				 				from "../../../other/ModalField"
import ModalFilterConfig 						from "../../../../@core/components/modal-filter-config"

const UserManagementTable = (props) => {

	const {
		data,
		params,
		getData,
		loading,
		setParams,
		pagination,
		setIsCreateModalVisible,
		setIsUpdateModalVisible,
		setIsDeleteModalVisible,
		isFilterModalVisible, 
		setIsFilterModalVisible,
	
		selected,
		setSelected,
		clearSelected,
		setClearSelected
	}										  = props

	const navigate 							  			  	= useNavigate()
    const [hideColumn, setHideColumn]         			  	= useState([])
	const [isHeaderConfigVisible, setIsHeaderConfigVisible] = useState(false) 

	const handleDetail = (id) => {
		navigate(`/configuration/user-management/${id}`)
	}

    const header        = [      
        {
			omit            : hideColumn[0] === false,
			name            : 'No',
            width           : '50px',
			center          : true,
			wrap            : true,
            compact         : true,
			cell            : row => (
                <>
                    {(row.index) + ((pagination.page - 1) * pagination.page_size)}
                </>
            ),
			sortable		: true
		},
		{
			omit            : hideColumn[1] === false,
			name            : 'Nama',
			center          : false,
			wrap            : true,
            compact         : true,
			selector        : row => row.name,
			cell            : row => (
                <div
					className	= "text-start pe-1"
				>
                   {row?.name}
                </div>
            ),
			sortable		: true
		},
        {
			omit            : hideColumn[2] === false,
			name            : 'NIP',
			center          : false,
			wrap            : true,
            compact         : true,
			selector        : row => row.nip,
			sortable		: true
		},
        {
			omit            : hideColumn[3] === false,
			name            : 'Satuan Kerja',
			center          : false,
			wrap            : true,
            compact         : true,
			cell            : row => (
                <div
					className	= "text-start pe-1"
				>
                   {row?.work_unit?.work_unit_level?.name} {row?.work_unit?.name}
                </div>
            ),
			sortable		: true
		},
        {
			omit            : hideColumn[4] === false,
			name            : 'Golongan & Pangkat',
			center          : false,
			wrap            : true,
            compact         : true,
			selector        : row => row?.grade?.name,
			sortable		: true
		},
        {
			omit            : hideColumn[5] === false,
			name            : 'Eselon',
			center          : false,
			wrap            : true,
            compact         : true,
			selector        : row => row.eselon,
			sortable		: true
		},
        {
			omit            : hideColumn[6] === false,
			name            : 'Jabatan',
			center          : false,
			wrap            : false,
            compact         : true,
			selector        : row => row?.position?.name,
			cell            : row => (
                <div
					className	= "text-start pe-1"
				>
                	{row?.position?.name}
                </div>
            ),
			sortable		: true
		},
        {
			omit            : hideColumn[7] === false,
			name            : 'Role',
			center          : false,
			wrap            : true,
            compact         : true,
			selector        : row => row?.role?.name,
			sortable		: true
		},
        {
			omit            : hideColumn[8] === false,
			name            : 'Aksi',
			center          : true,
			wrap            : true,
            compact         : true,
			cell            : row => (
                <>
                    <DropdownTableGlobal
                        data 			 = {data}
						row 			 = {row}
						showDetail  	 = {() => {
							handleDetail(row.id)
						}}
						showEdit 		 = {setIsUpdateModalVisible}
						showDelete 		 = {setIsDeleteModalVisible}
						setSelected 	 = {setSelected}
						setClearSelected = {setClearSelected}
                    />
                </>
            ),
			sortable		: true
		}
	]

    useEffect(() => {
		setHideColumn(() => header.map(() => true))
	}, [])

    return (
        <>
            <DataTablesGlobal
                data               		= {data}
                limit              		= {pagination.page_size}
                header             		= {header}
                params             		= {params}
                getData            		= {getData}
                loading            		= {loading}
                selected           		= {selected}
                totalData          		= {pagination.count}
                setParams          		= {setParams}
                setSelected        		= {setSelected}
                toggledClearRows   		= {clearSelected}
				setIsCreateModalVisible = {setIsCreateModalVisible}
				setIsDeleteModalVisible = {setIsDeleteModalVisible}
				setIsFilterModalVisible = {setIsFilterModalVisible}
				setIsHeaderConfigVisible= {setIsHeaderConfigVisible}
                disabledPaginationDefault = {true}
				visibility  	   		= {{
					filter          : true,
					delete			: true,
                    create          : true,
                    header_config   : true,
					search 			: true
                }}
            />

			<ModalField
                show        	= {isHeaderConfigVisible}
                setShow     	= {setIsHeaderConfigVisible}
                size        	= "sm"
                title       	= "Select"
                data        	= {header}
				hideColumn  	= {hideColumn}
				setHideColumn 	= {setHideColumn}
            />

        </>
    )
}

export default UserManagementTable