import React from 'react' 
import './CustomSpinner.css'   // Import file CSS

const CustomSpinner = (props) => {
    
    const {
        className,
        size,
        theme
    } = props

    return (
        <div 
            className={
                `spinner 
                ${className}
                ${size ? size : "md"}
                ${theme ? theme : "light"}
                `
            }
        >

        </div>
    ) 
} 

export default CustomSpinner 
