import { useEffect, useState }      from "react"
import { Button }                   from "reactstrap"
import { useNavigate }              from "react-router-dom"
import DropdownTableGlobal          from "../../../../@core/components/data-tables-global/dropdown"
import DataTablesGlobal             from "../../../../@core/components/data-tables-global"
import ModalField                   from "../../../other/ModalField"

const WorkunitTable = ({
    data,
	params,
	getData,
	loading,
	setParams,
	pagination,
	setIsCreateModalVisible,
	setIsUpdateModalVisible,
	setIsDeleteModalVisible,
	setIsFilterModalVisible,
	
    selected,
	setSelected,
	clearSelected,
	setClearSelected
}) => {

    const navigate 							              	= useNavigate()
    const [hideColumn, setHideColumn]                     	= useState([])
	const [isHeaderConfigVisible, setIsHeaderConfigVisible] = useState(false) 

    const handleDetail = (id) => {
		navigate(`/configuration/workunit/${id}`)
	}

    const header        = [      
        {
			omit            : hideColumn[0] === false,
			name            : 'No',
            width           : '50px',
			center          : false,
			wrap            : true,
            compact         : true,
			cell            : row => (
                <>
                    {(row.index) + ((pagination.page - 1) * pagination.page_size)}
                </>
            ),
			sortable		: true
		},
		{
			omit            : hideColumn[1] === false,
			name            : 'Nama Satuan Kerja',
			center          : false,
			wrap            : true,
            compact         : true,
			selector        : row => row.name,
			sortable		: true
		},
        {
			omit            : hideColumn[2] === false,
			name            : 'Kabupaten/ Provinsi',
			center          : false,
			wrap            : true,
            compact         : true,
			selector        : row => row.parent,
			sortable		: true
		},
        {
			omit            : hideColumn[3] === false,
			name            : 'Aksi',
			center          : true,
			wrap            : true,
            compact         : true,
			cell            : row => (
                <>
                    <DropdownTableGlobal
                        data 		        = {data}
						row 		        = {row}
                        showDetail  	 = {() => {
							handleDetail(row.id)
						}}
						showEdit 	        = {setIsUpdateModalVisible}
						showDelete 	        = {setIsDeleteModalVisible}
						setSelected         = {setSelected}
						setClearSelected    = {setClearSelected}
                    />
                </>
            ),
			sortable		: true
		}
	]

    useEffect(() => {
		setHideColumn(() => header.map(() => true))
	}, [])

    return (
        <>
            <DataTablesGlobal
                data               		= {data}
                limit              		= {pagination.page_size}
                header             		= {header}
                params             		= {params}
                getData            		= {getData}
                loading            		= {loading}
                selected           		= {selected}
                totalData          		= {pagination.count}
                setParams          		= {setParams}
                setSelected        		= {setSelected}
                toggledClearRows   		= {clearSelected}
				setIsCreateModalVisible = {setIsCreateModalVisible}
				setIsDeleteModalVisible = {setIsDeleteModalVisible}
				setIsFilterModalVisible = {setIsFilterModalVisible}
				setIsHeaderConfigVisible= {setIsHeaderConfigVisible}
				disabledPaginationDefault = {true}
				visibility  	   		= {{
					filter          : true,
					delete			: true,
                    create          : true,
                    header_config   : true,
					search 			: true
                }}
            />

            <ModalField
                show        	= {isHeaderConfigVisible}
                setShow     	= {setIsHeaderConfigVisible}
                size        	= "sm"
                title       	= "Select"
                data        	= {header}
				hideColumn  	= {hideColumn}
				setHideColumn 	= {setHideColumn}
            />
        </>
    )
}

export default WorkunitTable