import { Button, Form, Label }      from "reactstrap"
import { Controller, useForm }      from "react-hook-form"
import { useContext, useEffect }    from "react"

import { WorkunitContext }          from "../../../../context/workunitContext"
import { WorkunitLevelContext }     from "../../../../context/workunitLevelContext"

import Select                       from "react-select"
import ModalFilterConfig            from "../../../../@core/components/modal-filter-config"

const WorkunitFilter = (props) => {

    const {
        show,
        setShow,
        getData,
        params,
        setParams
    } = props

    const {
		reset,
        watch,
        getValues,
		control,
		setValue,
		setError,
		clearErrors,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode        : "onChange",
    })

    const {
        workunitOption
    } = useContext(WorkunitContext)

    const {
        workunitLevelOption
    } = useContext(WorkunitLevelContext)

    const onSubmit = (values) => {
        console.log("onSubmit1234", values)
        const filterParam = {
            parent             : values?.parent?.value,
            work_unit_level    : values?.workunit_level?.value,
        }

        const newParam = {
            ...params,
            ...filterParam,
            page        : 1,
        }

        setParams(newParam)

        getData(newParam)

        setShow(false)
    }

    const onReset = (values) => {

        const newParam = {
            page        : 1,
        }

        setParams(newParam)

        getData(newParam)

        setShow(false)

        reset()
    }

    return (
        <>
            <ModalFilterConfig
                show        = {show}
                setShow     = {setShow}
                size        = "sm"
                title       = "Filter Satuan Kerja"
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                
                    <div
                        className   = "mb-1"
                    >
                        <Label>Induk</Label>
                        <Controller
                            name        = "parent"
                            control     = {control}
                            render      = {({ field }) => (
                                <Select
                                    {...field} 
                                    className           = {`react-select ${errors.parent?.value && "is-invalid"}`}
                                    classNamePrefix     = "select"
                                    placeholder         = "Induk"
                                    options             = {workunitOption}
                                    isClearable         = {true}
                                />
                            )}
                        />
                        {errors.parent && <small className="text-danger">{errors.parent?.value?.message}</small>}
                    </div>

                    <div
                        className   = "mb-1"
                    >
                        <Label>Tingkat Satuan Kerja</Label>
                        <Controller
                            name        = "workunit_level"
                            control     = {control}
                            render      = {({ field }) => (
                                <Select
                                    {...field} 
                                    className           = {`react-select ${errors.workunit_level?.value && "is-invalid"}`}
                                    classNamePrefix     = "select"
                                    placeholder         = "Tingkat Satuan Kerja"
                                    options             = {workunitLevelOption}
                                    isClearable         = {true}
                                />
                            )}
                        />
                        {errors.workunit_level && <small className="text-danger">{errors.workunit_level?.value?.message}</small>}
                    </div>

                    <div
                        className       = "d-flex justify-content-between col-12 border-top pt-1"
                    >
                        <Button
                            color       = "primary"
                            outline
                            onClick     = {() => {
                                onReset()
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            color       = "primary"
                            type        = "submit"
                        >
                            Filter
                        </Button>
                    </div>
                </Form>
		    </ModalFilterConfig>
        </>
    )
}

export default WorkunitFilter