// ** React Imports
import { Fragment, useEffect, useState } from 'react'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Third Party Components
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Bell, X, Check, AlertTriangle, Maximize } from 'react-feather'

// ** Reactstrap Imports
import { Button, Badge, Input, DropdownMenu, DropdownItem, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import NotificationAPI from '../../../../service/pages/notification'
import toast from 'react-hot-toast'
import moment from "moment"
import reportAPI from '../../../../service/report'
import NotificationList from '../notification-dropdown-component'


const NotificationDropdown = () => {
	
	const [dropdownShow, setDropdownShow] = useState(false)
	const [listData, setListData] = useState([])
	const location 							  = useLocation()


	const navigate = useNavigate()

	// ** Notification Array

	const getNotification = () => {
		NotificationAPI.getNotification()
			.then((res) => {
				setListData(res.results)
			})
			.catch((err) => {
				toast.error('Get Notification Error')
			})
	}

	const multiNotificationHasRead = () => {		
		listData.map(item => {
			if (!item?.is_read) {
				NotificationAPI.notificationHasRead(item.id)
					.then(() => {
						toast.success('Notifikasi Telah Dibaca')
						getNotification()
					})
					.catch(() => {
						toast.error('Notification Has Reed Error')
					})
			}
		})		
	}

	const singleNotificationHasRead = (id) => {

		NotificationAPI.notificationHasRead(id)
			.then(() => {
				toast.success('Notifikasi Telah Dibaca')
				getNotification()
			})
			.catch(() => {
				toast.error('Notification Has Reed Error')
			})		
	}


	useEffect(() => {
		getNotification()
	}, [])

	// ** Function to render Notifications
	/*eslint-disable */
	const renderNotificationItems = () => {
		return (
			<PerfectScrollbar
				component='li'
				className='media-list scrollable-container'
				options={{
					wheelPropagation: false
				}}
			>
				{listData?.length > 0 && listData.map((item, index) => {
					return (
						<NotificationList 
							item = {item}
							index = {index}
							singleNotificationHasRead = {singleNotificationHasRead}
						/>
					)
				})}
				{
					Array.isArray(listData) && listData?.length === 0 && (
						<h4>
							Tidak Ada Pesan
						</h4>
					)
				}
			</PerfectScrollbar>
		)
	}
	/*eslint-enable */

	return (
		<>
		{
			location.pathname !== '/notification' && (
				<UncontrolledDropdown 
					tag='li' 
					className='dropdown-notification nav-item me-25'
					isOpen={dropdownShow}
					toggle={() => setDropdownShow(!dropdownShow)}
				>
					<DropdownToggle 
						tag='a' 
						className='nav-link' 
						href='/' 
						onClick={(e) => {
							setDropdownShow(!dropdownShow)
							e.preventDefault()
						}}
					>
						<div>
							<Bell size={21} className='text-primary' />
							<Badge pill color='danger' className='badge-up'>
								{listData?.filter(item => item.is_read === false).length}
							</Badge>

						</div>
					</DropdownToggle>
					<DropdownMenu end tag='ul' className='dropdown-menu-media mt-0'>
						<li className='dropdown-menu-header'>
							<DropdownItem className='d-flex' tag='div' header>
								<h4 className='notification-title mb-0 me-auto'>Notifikasi</h4>
								<Maximize
									size={20}
									className='me cursor-pointer'
									onClick={() => {
										setDropdownShow(!dropdownShow)
										navigate("/notification")
									}}
								/>
							</DropdownItem>
						</li>
						{renderNotificationItems()}
						<li className='dropdown-menu-footer'>
							<Button color='primary' disabled = {listData.length === 0 || listData?.filter(item => item.is_read === false).length === 0} block onClick={multiNotificationHasRead}>
								Tandai Sudah Dibaca
							</Button>
						</li>
					</DropdownMenu>
				</UncontrolledDropdown>
			)
		}
		</>
	)
}

export default NotificationDropdown
