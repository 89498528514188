import moment from "moment"
import { useEffect, useState }                 from "react"
import DataTablesGlobal                        from "../../../../@core/components/data-tables-global"
import ModalField                              from "../../../other/ModalField"

const UserActivityTable = ({
    data,
	params,
	getData,
	loading,
	setParams,
	pagination,
	setIsDeleteModalVisible,
	setIsFilterModalVisible,
    selected,
	setSelected,
	clearSelected,
	setClearSelected
}) => {
    
    const [hideColumn, setHideColumn]                       = useState([])
	const [isHeaderConfigVisible, setIsHeaderConfigVisible] = useState(false) 

    const header        = [      
        {
			omit            : hideColumn[0] === false,
			name            : 'Tanggal',
            width           : '100px',
			center          : false,
			wrap            : true,
            compact         : true,
			cell            : (row) => (
                <>{moment(row.action_time).format("DD-MM-YYYY")}</>
            ),
			sortable		: true
		},
		{
			omit            : hideColumn[1] === false,
			name            : 'Nama',
            width           : '200px',
			center          : false,
			wrap            : true,
            compact         : true,
			selector        : row => row.name,
			sortable		: true
		},
        {
			omit            : hideColumn[2] === false,
			name            : 'Aktivitas',
			center          : false,
			wrap            : true,
            compact         : false,
			selector        : row => row.change_message,
			cell            : (row) => (
                <>
					<div className="text-start">
						{row?.change_message ? row?.change_message : row?.object_repr}
					</div>
				</>
            ),
			sortable		: true
		},
        {
			omit            : hideColumn[3] === false,
			name            : 'Akses',
            width           : '200px',
			center          : false,
			wrap            : true,
            compact         : true,
			selector        : row => row.deviceName,
			sortable		: true 
		}
	]

    useEffect(() => {
		setHideColumn(() => header.map(() => true))
	}, [])

    return (
        <>
            <DataTablesGlobal
                data               		= {data}
                limit              		= {pagination?.page_size}
                header             		= {header}
                params             		= {params}
                getData            		= {getData}
                loading            		= {loading}
                selected           		= {selected}
                totalData          		= {pagination?.count}
                setParams          		= {setParams}
                setSelected        		= {setSelected}
                toggledClearRows   		= {clearSelected}
				setIsDeleteModalVisible = {setIsDeleteModalVisible}
				setIsFilterModalVisible = {setIsFilterModalVisible}
				setIsHeaderConfigVisible= {setIsHeaderConfigVisible}
				disabledPaginationDefault = {true}
                visibility  	        = {{
                    filter          : true,
                    header_config   : true,
					delete			: false,
					search 			: true
                }}
            />

            <ModalField
                show        	= {isHeaderConfigVisible}
                setShow     	= {setIsHeaderConfigVisible}
                size        	= "sm"
                title       	= "Select"
                data        	= {header}
				hideColumn  	= {hideColumn}
				setHideColumn 	= {setHideColumn}
            />
        </>
    )
}

export default UserActivityTable