import { 
    useState,
    useEffect, 
    useContext
}                                           from "react"
import UserManagementTable                  from "./component/UserManagementTable"
import UserManagementCreate                 from "./component/UserManagementCreate"
import UserManagementUpdate                 from "./component/UserManagementUpdate"
import ModalDelete                          from "../../other/ModalDelete"

// Context
import { UserManagementContext }            from "../../../context/userManagementContext"

// API
import userManagementAPI                    from "../../../service/pages/user-management"
import toast                                from "react-hot-toast"
import serviceAPI                           from "../../../service/drive/service"
import UserManagementFilter                 from "./component/UserManagementFilter"

const UserManagementConfiguration = () => {

    const [selected, setSelected]                         = useState([])
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)
    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [isFilterModalVisible, setIsFilterModalVisible] = useState(false)

    const [params, setParams]                             = useState({})
    
    const {
        getData,
        pagination,
        setPagination,

        isLoading,

        listData,
        setListData
    }                                         = useContext(UserManagementContext)

    const [clearSelected, setClearSelected]   = useState(false)

    const handleCreate = async (data, account, profilePicture) => {
        const payload = {
            user          : 0,
            name          : data?.name !== undefined ? data?.name : "",
            email         : data?.email !== undefined ? data?.email : "",
            address       : data?.address !== undefined ? data?.address : "",
            phone_number  : data?.phone_number !== undefined ? data?.phone_number : "",
            photo         : "",
            grade_id      : data?.grade !== undefined ? data?.grade.value : 0,
            role_id       : data?.role !== undefined ? data?.role.value : 0,
            position_id   : data?.position !== undefined ? data?.position.value : 0,
            work_unit_id  : data?.workunit !== undefined ? data?.workunit.value : 0,
            nip           : data?.nip !== undefined ? data?.nip : "",
            classes_id    : data?.class !== undefined ? data?.class.value : 0,
            sector_id     : data?.sector !== undefined ? data?.sector.value : 0,
            code_id          : data?.bidang !== undefined ? data?.bidang : 0,
            code_sector_id   : data?.sektor !== undefined ? data?.sektor : 0,
            code_subdir_id   : data?.subdirektorat !== undefined ? data?.subdirektorat : 0,
            code_seksi_id    : data?.seksi !== undefined ? data?.seksi : 0,
        }

        if (profilePicture) {
            const dataPhoto = new FormData()
            dataPhoto.append("service", "user-management")
            dataPhoto.append("file", profilePicture)
      
            const resFoto = await serviceAPI.uploadFileService(dataPhoto)
            
            payload.photo = resFoto.data.url_file
        }

        console.log("GETHANDLECREATE", payload)

        if (account !== null) {
            let validate = true

            if (account.username === "") {
                validate = false
                toast.error("Isi Nama Pengguna Terlebih Dahulu!")
            } else if (account.password1 === "") {
                validate = false
                toast.error("Isi Password Terlebih Dahulu!")
            } else if (account.password2 === "") {
                validate = false
                toast.error("Isi Konfirmasi Password Terlebih Dahulu!")
            } else if (account.password1 !== account.password2) {
                validate = false
                toast.error("Isi Password Tidak Sama!")
            }

            if (validate) {
                const payloadAccount = {
                    is_superuser : data?.is_superuser,
                    username     : account.username,
                    password     : account.password1
                }

                console.log("payloadAccount", payloadAccount)

                userManagementAPI.createUserAuth(payloadAccount)
                    .then((res) => {
                        payload.user_id = res.id
                        userManagementAPI.createUserManagement(payload)
                        .then((res) => {
                            toast.success("Pengguna Berhasil Dibuat")
                            getData()
                            setIsCreateModalVisible(false)
                        })
                        .catch((err) => {
                            toast.error("Pengguna Gagal Dibuat")
                            userManagementAPI.deleteUserLogin(res.id)
                        })
                    })
                
            }
        } else {
            validate = false
            toast.error("Isi Nama Pengguna & Password Terlebih Dahulu!")
        }
    }

    const handleUpdate = async (data, profilePicture) => {
        const payload = {
            user          : selected[0]?.user_id,
            name          : data?.name !== undefined ? data?.name : "",
            email         : data?.email !== undefined ? data?.email : "",
            address       : data?.address !== undefined ? data?.address : "",
            phone_number  : data?.phone_number !== undefined ? data?.phone_number : "",
            photo         : "",
            grade_id      : data?.grade !== undefined ? data?.grade.value : 0,
            role_id       : data?.role !== undefined ? data?.role.value : 0,
            position_id   : data?.position !== undefined ? data?.position.value : 0,
            work_unit_id  : data?.workunit !== undefined ? data?.workunit.value : 0,
            nip           : data?.nip !== undefined ? data?.nip : "",
            classes_id    : data?.class !== undefined ? data?.class.value : 0,
            sector_id     : data?.sector !== undefined ? data?.sector.value : 0,
            code_id          : data?.bidang !== undefined ? data?.bidang : 0,
            code_sector_id   : data?.sektor !== undefined ? data?.sektor : 0,
            code_subdir_id   : data?.subdirektorat !== undefined ? data?.subdirektorat : 0,
            code_seksi_id    : data?.seksi !== undefined ? data?.seksi : 0,
        }

        if (profilePicture) {
            const dataPhoto = new FormData()
            dataPhoto.append("service", "user-management")
            dataPhoto.append("file", profilePicture)
      
            const resFoto = await serviceAPI.uploadFileService(dataPhoto)
            
            payload.photo = resFoto.data.url_file
        }

        userManagementAPI.updateUserManagement(payload, selected[0]?.id)
            .then((res) => {
                toast.success("Data Pengguna Berhasil Diubah")
                getData()
                setIsUpdateModalVisible(false)
            })

        const payloadAccount = {
            is_superuser : data?.is_superuser,
            username     : selected[0]?.user?.username,
        }

        userManagementAPI.updateUserAuth(payloadAccount, selected[0]?.user_id)
            .then((res) => {

            })
            
    }

    const handleDelete = () => {
        if (selected.length > 0) {
            selected.map((data) => (
                userManagementAPI.deleteUserManagement(data.id).then(
                    () => {
                        getData(params)
                    }
                ).catch( 
                    err => {
                        console.log(err)
                    }
                )
            ))

            setSelected([])
        }
    }

    useEffect(() => {
        if (clearSelected) {
            setClearSelected(!clearSelected)
        }
    }, [clearSelected])

    useEffect(() => {
        if (!isUpdateModalVisible) {
            setSelected([])
            setClearSelected(true)
        }
    }, [isUpdateModalVisible])

    useEffect(() => {
        if (!isDeleteModalVisible) {
            setSelected([])
            setClearSelected(true)
        }
    }, [isDeleteModalVisible])

    return (
        <>
            <UserManagementTable
                data                    = {listData}
                params                  = {params}
                setData                 = {setListData}
                getData                 = {getData}
                loading                 = {isLoading}
                selected                = {selected}
                setParams               = {setParams}
                pagination              = {pagination}
                setSelected             = {setSelected}
                clearSelected           = {clearSelected}
                setClearSelected        = {setClearSelected}
                setIsCreateModalVisible = {setIsCreateModalVisible}
                setIsUpdateModalVisible = {setIsUpdateModalVisible}
                setIsDeleteModalVisible = {setIsDeleteModalVisible}
                isFilterModalVisible    = {isFilterModalVisible}
                setIsFilterModalVisible = {setIsFilterModalVisible}
            />

            <UserManagementCreate
                size            = "lg"
                show            = {isCreateModalVisible}
                title           = "Tambah Pengguna"
                setShow         = {setIsCreateModalVisible}
                handleCreate    = {handleCreate}
            />

            <UserManagementUpdate
                data            = {selected?.length === 1 ? selected[0] : null}
                size            = "lg"
                show            = {isUpdateModalVisible}
                title           = "Ubah Pengguna"
                setShow         = {setIsUpdateModalVisible}
                handleUpdate    = {handleUpdate}
            />

            <ModalDelete
                data            = {selected}
                show            = {isDeleteModalVisible}
                size            = "sm"
                title           = "Hapus Pengguna"
                setShow         = {setIsDeleteModalVisible}
                handleDelete    = {handleDelete}
            />

            <UserManagementFilter
                show            = {isFilterModalVisible}
                setShow         = {setIsFilterModalVisible}
                getData         = {getData}
                params          = {params}
                setParams       = {setParams}
            />

        </>
    )
}

export default UserManagementConfiguration